import actions from '../actions/layerSources';

const initialState: any = {};

const layerSources = (state = initialState, action): any => {
    switch (action.type) {
        case actions.SET_SOURCE:
            const newSource = action.source;
            if (newSource.data) {
                newSource.data = JSON.parse(newSource.data);
            }
            return {
                ...state,
                ...{ [action.id]: newSource }
            };
        case actions.CLEAR_SOURCE:
            const newState = { ...state };
            delete newState[action.id];
            return newState;
        case actions.CLEAR_ALL:
            return {};
        default:
            return state;
    }
};

export default layerSources;
