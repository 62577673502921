export const STORY_ERRORS = {
    CUT_ERROR: 'CUT_ERROR',
    OVERLAY_ERROR: 'OVERLAY_ERROR',
    CLIP_SWAP: 'CLIP_SWAP',
    OVERLAY_ASSET_ERROR: 'OVERLAY_ASSET_ERROR',
    OVERLAY_ASSET_TAG_ERROR: 'OVERLAY_ASSET_TAG_ERROR',
    OVERLAY_SCRIPT_ERROR: 'OVERLAY_SCRIPT_ERROR',
    OVERLAY_VARIABLE_ERROR: 'OVERLAY_VARIABLE_ERROR',
    OVERLAY_RECT_ERROR: 'OVERLAY_RECT_ERROR',
    OVERLAY_CORNER_PIN_ERROR: 'OVERLAY_CORNER_PIN_ERROR',
    OVERLAY_RECT_SEQUENCE_ERROR: 'OVERLAY_RECT_SEQUENCE_ERROR',
    OVERLAY_CORNER_PIN_SEQUENCE_ERROR: 'OVERLAY_CORNER_PIN_SEQUENCE_ERROR',
    OVERLAY_EFFECT_MASK_MISSING: 'OVERLAY_EFFECT_MASK_MISSING',
    OVERLAY_ASSET_NOT_FOUND: 'OVERLAY_ASSET_NOT_FOUND'
};
