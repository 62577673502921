import { IDatasetListItem } from '../../components/data/DatasetEditor';
import {
    addColumnHeader,
    addColumnToDataset,
    addDuplicateRowToDataset,
    addRowToDataset,
    formatDatasetArrayFromCSV,
    removeColumnsFromDataset,
    removeColumnsFromHeaders,
    removeRowsFromDataset,
    updateDataset
} from '../../util/dataset';
import actions from '../actions/datasets';

export interface IDatasets {
    datasetList: IDatasetListItem[];
    loadingDataset: boolean;
    activeDataset: any;
}

const initialState: IDatasets = {
    datasetList: [],
    loadingDataset: false,
    activeDataset: null
};

const datasets = (state = initialState, action) => {
    switch (action.type) {
        case actions.RESET:
            return {
                ...initialState
            };
            break;
        case actions.SET_DATASET_LIST:
            return {
                ...state,
                datasetList: action.datasetList
            };
            break;
        case actions.SET_ACTIVE_DATASET:
            const modified = formatDatasetArrayFromCSV(action.dataset);
            return {
                ...state,
                activeDataset: modified
            };

        case actions.CLEAR_ACTIVE_DATASET:
            return {
                ...state,
                activeDataset: null
            };
        case actions.SET_ACTIVE_DATASET_DATA:
            return {
                ...state,
                activeDataset: {
                    ...state.activeDataset,
                    data: updateDataset(state.activeDataset.data, action.data)
                }
            };
        case actions.UPDATE_ACTIVE_DATASET:
            return {
                ...state,
                activeDataset: { ...state.activeDataset, ...action.dataset }
            };
        case actions.ADD_ROW:
            return {
                ...state,
                activeDataset: {
                    ...state.activeDataset,
                    data: addRowToDataset(state.activeDataset.data, action.index, action.rows)
                }
            };
        case actions.DUPLICATE_ROW:
            return {
                ...state,
                activeDataset: {
                    ...state.activeDataset,
                    data: addDuplicateRowToDataset(state.activeDataset.data, action.index)
                }
            };
        case actions.ADD_COLUMN:
            return {
                ...state,
                activeDataset: {
                    ...state.activeDataset,
                    data: addColumnToDataset(state.activeDataset.data),
                    headers: addColumnHeader(state.activeDataset.headers, action.columnHeader)
                }
            };

        case actions.DELETE_ROWS:
            return {
                ...state,
                activeDataset: {
                    ...state.activeDataset,
                    data: removeRowsFromDataset(state.activeDataset.data, action.range)
                }
            };

        case actions.DELETE_COLUMNS:
            return {
                ...state,
                activeDataset: {
                    ...state.activeDataset,
                    data: removeColumnsFromDataset(state.activeDataset.data, action.columns),
                    headers: removeColumnsFromHeaders(state.activeDataset.headers, action.columns)
                }
            };
        case actions.SET_ACTIVE_DATASET_NAME:
            const datasetList = [...state.datasetList];
            const itemIndex = datasetList.findIndex((d) => {
                return d.id === state.activeDataset.id;
            });
            const item = { ...datasetList[itemIndex] };
            item.name = action.name;
            datasetList[itemIndex] = item;

            return {
                ...state,
                activeDataset: { ...state.activeDataset, name: action.name },
                datasetList
            };
        case actions.SET_ACTIVE_DATASET_COLUMN_WIDTHS:
            const newDatasetList = [...state.datasetList];
            const index = newDatasetList.findIndex((d) => {
                return d.id === state.activeDataset.id;
            });
            const newItem = { ...newDatasetList[index] };
            newItem.column_widths = action.column_widths;
            newDatasetList[index] = newItem;

            return {
                ...state,
                datasetList: newDatasetList
            };
        case actions.SET_LOADING:
            return {
                ...state,
                loadingDataset: action.loading
            };
        case actions.INSERT_DATASET:
            return {
                ...state,
                datasetList: [action.dataset, ...state.datasetList]
            };
        case actions.UPDATE_DATASET:
            return {
                ...state,
                datasetList: action.dataset
            };
        case actions.REMOVE_DATASET:
            const dIdx = state.datasetList.findIndex((d) => {
                return d.id === action.id;
            });
            return {
                ...state,
                datasetList: [
                    ...state.datasetList.slice(0, dIdx),
                    ...state.datasetList.slice(dIdx + 1)
                ]
            };
        default:
            return state;
    }
};

export default datasets;
