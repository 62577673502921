import actions from '../actions/batches';

const initialState: any = {
    loading: false,
    filters: {
        page: 1,
        items_per_page: 25,
        order: 'date_created',
        order_direction: 'desc'
    },
    selected: {},
    data: {}
};

const batchesList = (state = initialState, action): any => {
    switch (action.type) {
        case actions.SET_LOADING:
            return {
                ...state,
                loading: action.toggle
            };
        case actions.SELECT:
            return {
                ...state,
                selected: { ...action.selectedBatch }
            };
        case actions.GET:
            return {
                ...state,
                data: { ...action.batches }
            };
        case actions.DELETE:
            return {
                ...state,
                selected: {}
            };
        case actions.FORCE_RENDER_STATUS:
            return {
                ...state,
                data: {
                    ...state.data,
                    batches: state.data.batches.map((b: any) => {
                        if (b.id === action.batchId) {
                            return { ...b, status: action.forcedRenderStatus };
                        }

                        return b;
                    })
                }
            };
        case actions.ERROR: {
            return {
                ...state,
                error: { ...state.error, [action.error.id]: { ...action.error } }
            };
        }
        default:
            return state;
    }
};

export default batchesList;
