import actions from '../actions/errors';

export interface IErrors {
    cuts: any;
    overlays: any;
    audios: any;
}

const initialState: IErrors = {
    cuts: [],
    overlays: [],
    audios: []
};

const errors = (state = initialState, action): any => {
    switch (action.type) {
        case actions.CUT_ERROR:
            return {
                ...state,
                cuts: [...action.cutErrors]
            };

        case actions.CLEAR_CUT_ERROR:
            return {
                ...state,
                cuts: []
            };

        case actions.OVERLAY_ERROR:
            return {
                ...state,
                overlays: [...action.overlayErrors]
            };

        case actions.CLEAR_OVERLAY_ERROR:
            return {
                ...state,
                overlays: []
            };

        case actions.AUDIO_ERROR:
            return {
                ...state,
                audios: [...action.audioErrors]
            };

        case actions.CLEAR_AUDIO_ERROR:
            return {
                ...state,
                audios: []
            };

        default:
            return state;
    }
};

export default errors;
