import editor from './editor';

const timeline: any = {
    UPDATE: 'timeline/UPDATE'
};

export const updateTimelineState = (config): any => {
    return (dispatch, getStore) => {
        const {
            timeline: { activeFrame },
            project: { compositionId }
        } = getStore();
        if (config.activeFrame >= 0) {
            const newConfig = { ...config };
            const compActiveFrame = { [compositionId]: config.activeFrame };
            const updateActiveFrame = { ...activeFrame, ...compActiveFrame };
            newConfig.activeFrame = updateActiveFrame;
            dispatch({ type: timeline.UPDATE, config: newConfig });
        } else {
            dispatch({ type: timeline.UPDATE, config });
        }
        if (
            config.activeTimelineLayer ||
            (config.activeMultiSelectLayers && config.activeMultiSelectLayers.length > 0)
        ) {
            dispatch({
                type: editor.UPDATE,
                config: {
                    expandPanel: false
                }
            });
        }
    };
};

export default timeline;
