export const assetCache: any = {
    CLEAR_ASSET_CACHE: 'CLEAR_ASSET_CACHE',
    ADD_ASSET_TO_CACHE: 'ADD_ASSET_TO_CACHE'
};

// TODO: all error handling!

export const clearAssetCache = (): any => {
    return (dispatch) => {
        dispatch({ type: assetCache.CLEAR_ASSET_CACHE });
    };
};

export const addItemToAssetCache = (assetCacheItem): any => {
    return (dispatch) => {
        dispatch({ type: assetCache.ADD_ASSET_TO_CACHE, assetCacheItem });
    };
};

export default assetCache;
