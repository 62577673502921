import * as PIXI from 'pixi.js-legacy';

export const layerSourceError = ({
    bgColor,
    copyColor,
    copyAlpha,
    copy,
    x,
    y,
    width,
    height,
    bgAlpha,
    fontSize
}) => {
    const color = bgColor;
    const textureRect = new PIXI.Rectangle(x, y, width, height);
    const tex = new PIXI.Texture(new PIXI.BaseTexture(), textureRect, textureRect);
    const containerSprite = new PIXI.Sprite(tex);

    const bgSprite = new PIXI.Graphics();
    bgSprite.beginFill(color, bgAlpha);
    bgSprite.drawRect(0, 0, width, height);

    const styleConfig = {
        fill: copyColor,
        fontSize,
        fontFamily: 'Oswald, sans-serif'
    };

    const style = new PIXI.TextStyle(styleConfig);
    const textSprite: any = new PIXI.Text(copy, style);
    textSprite.alpha = copyAlpha;
    const dims = textSprite.getLocalBounds();

    // position withing the bounds
    const textX = (width - dims.width) / 2;
    const textY = (height - dims.height) / 2;

    textSprite.x = textX;
    textSprite.y = textY;

    containerSprite.addChild(bgSprite);
    containerSprite.addChild(textSprite);

    return containerSprite;
};
