const changes: any = {
    UPDATE: 'changes/UPDATE',
    UPDATE_COMP: 'changes/UPDATE_COMP',
    UPDATE_TEMPLATE: 'changes/UPDATE_TEMPLATE'
};

export const updateChanges = (config): any => {
    return (dispatch) => {
        dispatch({ type: changes.UPDATE, config });
    };
};

export default changes;
