import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

export default class ServiceAPI {
    private static readonly retry: any = axiosRetry as any;

    private http: any = null;

    private serviceBaseUrl: string = '';

    constructor(serviceBaseUrl: string, idToken: string) {
        this.serviceBaseUrl = serviceBaseUrl;

        this.http = axios.create({
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });

        ServiceAPI.retry(this.http, { retryDelay: ServiceAPI.retry.exponentialDelay });
    }

    public getQueues = (): Promise<any> => {
        return this.doRequest({
            method: 'GET',
            url: this.serviceBaseUrl
        });
    };

    private doRequest = (config: AxiosRequestConfig): Promise<any> => {
        return new Promise((resolve, reject) => {
            this.http(config)
                .then((res: AxiosResponse) => {
                    const { data } = res;
                    resolve(data);
                })
                .catch((e: AxiosError) => {
                    reject(e);
                });
        });
    };
}
