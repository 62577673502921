import { saveAs } from 'file-saver';
import { api } from '../../constants/app';
import batchActions, { getBatches, forceRenderStatus } from './batches';
import { BATCHES_COPY } from '../../constants/copy';
import { log } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/story';

const actions: any = {
    ADD_MISSING: 'batchJobs/ADD_MISSING',
    RESET_MISSING: 'batchJobs/RESET_MISSING',
    ADD_JOB: 'batchJobs/ADD_JOB',
    REMOVE_JOB: 'batchJobs/REMOVE_JOB',
    ADD_RENDER_STATUS: 'batchJobs/ADD_RENDER_STATUS',
    UPDATE_RENDER_STATUS: 'batchJobs/UPDATE_RENDER_STATUS',
    REMOVE_RENDER_STATUS: 'batchJobs/REMOVE_RENDER_STATUS',
    SET_FAILED: 'batchJobs/SET_FAILED',
    RESET_FAILED: 'batchJobs/RESET_FAILED'
};
const EXPORTS_JOB_KEY: string = 'exports';
const NO_EXPORT_MESSAGE: string = 'No exports available';
const JOB_COMPLETE_STATUS: string = 'completed';
const JOB_POLL_INTERVAL: number = 1000;
const RENDER_COMPLETE_PERC: number = 100;
const RENDER_CHECK_POLL_INTERVAL: number = 5000;

export const importBatchFromCsv = (
    storyId: string,
    batchId: string,
    csvFile: File,
    accessKey: string,
    compositionId: string,
    addEmbed?: boolean,
    addMedia?: boolean
): any => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            api.getSignedUrl(storyId, csvFile)
                .then((resSignedUrl) => {
                    api.uploadToSignedUrl(resSignedUrl.signed_url, csvFile)
                        .then(() => {
                            api.importBatchDataFromCsv(
                                batchId,
                                resSignedUrl.file_key,
                                addEmbed,
                                accessKey,
                                compositionId,
                                addMedia
                            )
                                .then((importStatus: any) => {
                                    const { job_id: jobId, missing_columns } = importStatus;

                                    if (missing_columns && missing_columns !== undefined) {
                                        const missingColumns: string[] = Object.keys(
                                            missing_columns
                                        ).map((c) => {
                                            return missing_columns[c];
                                        });

                                        dispatch({ type: actions.ADD_MISSING, missingColumns });
                                        resolve(importStatus);
                                    } else {
                                        dispatch({ type: actions.RESET_MISSING });
                                        doPollJobCompletionStatus(jobId)
                                            .then(() => {
                                                dispatch(getBatches());
                                                resolve();
                                            })
                                            .catch((e: Error) => {
                                                reject(e);
                                                throw e;
                                            });
                                    }
                                })
                                .catch((e) => {
                                    const {
                                        response: { data }
                                    } = e;
                                    if (data?.error) {
                                        dispatch(log(`${data.error}`, NOTIFICATION_TYPES.ERROR));
                                    }
                                    reject(e);
                                    throw e;
                                });
                        })
                        .catch((e: Error) => {
                            reject(e);
                            throw e;
                        });
                })
                .catch((e: Error) => {
                    reject(e);
                });
        });
    };
};

export const importBatchFromDataset = (
    batchId: string,
    datasetId: string,
    accessKey: string,
    compositionId: string,
    addEmbed?: boolean,
    addMedia?: boolean
): any => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            api.importBatchDataFromDataset(
                batchId,
                datasetId,
                addEmbed,
                accessKey,
                compositionId,
                addMedia
            )
                .then((importStatus: any) => {
                    const { job_id: jobId, missing_columns } = importStatus;

                    if (missing_columns !== undefined) {
                        const missingColumns: string[] = Object.keys(missing_columns).map((c) => {
                            return missing_columns[c];
                        });

                        dispatch({ type: actions.ADD_MISSING, missingColumns });
                    } else {
                        dispatch({ type: actions.RESET_MISSING });
                    }

                    doPollJobCompletionStatus(jobId)
                        .then(() => {
                            dispatch(getBatches());
                            resolve();
                        })
                        .catch((e: Error) => {
                            reject(e);
                            throw e;
                        });
                })
                .catch((e: Error) => {
                    reject(e);
                    throw e;
                });
        });
    };
};

export const getBatchExport = (batchId: string): any => {
    return (dispatch, getState) => {
        const state = getState();
        return new Promise<void>((resolve, reject) => {
            dispatch({ type: actions.ADD_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
            api.checkBatchExportStatus(batchId)
                .then(async (exportStatus: any) => {
                    if (exportStatus.hasOwnProperty('id')) {
                        await downloadExport(batchId, exportStatus.id, state, dispatch);
                        resolve();
                    }

                    if (
                        !exportStatus.hasOwnProperty('id') &&
                        exportStatus.message === NO_EXPORT_MESSAGE
                    ) {
                        await processNewBatchExport(batchId, state, dispatch);
                        resolve();
                    }
                })
                .catch((e: Error) => {
                    dispatch({ type: actions.REMOVE_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
                    reject(e);
                });
        });
    };
};

const processNewBatchExport = (batchId: string, state, dispatch): any => {
    return new Promise<void>((resolve, reject) => {
        api.invokeBatchExportJob(batchId)
            .then((jobDetails: any) => {
                const { job_id: jobId } = jobDetails;

                doPollJobCompletionStatus(jobId)
                    .then(() => {
                        api.checkBatchExportStatus(batchId)
                            .then(async (exportStatus: any) => {
                                await downloadExport(batchId, exportStatus.id, state, dispatch);
                                resolve();
                            })
                            .catch((e: Error) => {
                                reject();
                                throw e;
                            });
                    })
                    .catch((e: Error) => {
                        reject();
                        throw e;
                    });
            })
            .catch((e: Error) => {
                dispatch({ type: actions.REMOVE_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
            });
    });
};

const downloadExport = (batchId: string, exportId: number, state, dispatch): any => {
    return new Promise<void>((resolve, reject) => {
        const {
            batchesList: {
                data: { batches }
            }
        } = state;
        const selectedBatch: any = batches.find((b: any) => b.id === batchId);

        api.downloadBatchExport(batchId, exportId)
            .then((batchBlob: Blob) => {
                saveAs(batchBlob, `${selectedBatch.name}.csv`);
                dispatch({ type: actions.REMOVE_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
                resolve();
            })
            .catch((e: Error) => {
                dispatch({ type: actions.REMOVE_JOB, jobKey: EXPORTS_JOB_KEY, batchId });
                reject();
            });
    });
};

export const doPollJobCompletionStatus = (jobId: string): any => {
    return new Promise<void>((resolve, reject) => {
        let timeout: number;

        const runPoll = (): void => {
            clearTimeout(timeout);

            api.getJob(jobId)
                .then((job: any) => {
                    if (!job.processing && job.status === JOB_COMPLETE_STATUS) {
                        resolve();
                    } else {
                        timeout = window.setTimeout(() => runPoll(), JOB_POLL_INTERVAL);
                    }
                })
                .catch((e: Error) => {
                    reject(e);
                });
        };
        runPoll();
    });
};

export const renderBatch = (batchId: string, postRenderActions?: any): any => {
    let updateDelegate: (p: number) => void;
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            dispatch({ type: actions.RESET_FAILED });
            dispatch({ type: actions.ADD_RENDER_STATUS, batchId });

            api.prepareBatchForRender(batchId)
                .then(() => {
                    api.invokeBatchRenderJob(batchId, postRenderActions)
                        .then(() => {
                            updateDelegate = (p) => dispatch(updateRenderStatus(batchId, p));
                            dispatch(forceRenderStatus(batchId, 'rendering'));
                            dispatch(doPollRenderCompletionStatus(batchId, updateDelegate))
                                .then(() => {
                                    dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                                    resolve();
                                })
                                .catch((e: Error) => {
                                    if (e) {
                                        dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                                        reject(e);
                                    }
                                });
                        })
                        .catch((e) => {
                            const { status } = e.response;
                            if (status === 402) {
                                dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                            }
                            reject(e);
                        });
                })
                .catch((e: Error) => {
                    dispatch({ type: actions.REMOVE_RENDER_STATUS, batchId });
                    reject(e);
                });
        });
    };
};

export const updateRenderStatus = (batchId: string, p: number): any => {
    return (dispatch, getStore) => {
        const { batchJobs } = getStore();
        const currentPerc: any = batchJobs.renders[batchId];
        if (p > currentPerc) {
            dispatch({ type: actions.UPDATE_RENDER_STATUS, percentComplete: p, batchId });
        }
    };
};

export const resetBatchFailedStatus = () => {
    return (dispatch) => {
        dispatch({ type: actions.RESET_FAILED });
    };
};

const doPollRenderCompletionStatus = (batchId: string, onUpdate: (perc: number) => void): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            let timeout: number;

            const runPoll = (): void => {
                const { batchJobs } = getStore();
                clearTimeout(timeout);

                if (!batchJobs.renders.hasOwnProperty(batchId)) {
                    return reject();
                }
                api.getBatchProgress(batchId)
                    .then((progressStatus: any) => {
                        const {
                            rows_rendered: rowsRendered,
                            total_rows: totalRows,
                            status
                        } = progressStatus;
                        const percentComplete: number = Math.floor(
                            (rowsRendered / totalRows) * 100
                        );
                        if (
                            (percentComplete === RENDER_COMPLETE_PERC &&
                                status === BATCHES_COPY.progress.rendering) ||
                            status === BATCHES_COPY.progress.rendered ||
                            status === BATCHES_COPY.progress.cancelled
                        ) {
                            resolve();
                        } else if (status === BATCHES_COPY.progress.failed) {
                            api.getBatchError(batchId)
                                .then((error) => {
                                    dispatch({ type: batchActions.ERROR, error });
                                })
                                .catch((e) => console.error(e));
                            dispatch({ type: actions.SET_FAILED });
                            resolve();
                        } else {
                            if (status !== BATCHES_COPY.progress.queued) {
                                onUpdate(percentComplete);
                            }

                            timeout = window.setTimeout(
                                () => runPoll(),
                                RENDER_CHECK_POLL_INTERVAL
                            );
                        }
                    })
                    .catch((e: Error) => {
                        reject(e);
                    });
            };
            runPoll();
        });
    };
};

export default actions;
