import { api } from '../../constants/app';

const batchActions: any = {
    SET_LOADING: 'batches/SET_LOADING',
    SELECT: 'batches/SELECT',
    GET: 'batches/GET',
    FORCE_RENDER_STATUS: 'batches/FORCE_RENDER_STATUS',
    ERROR: 'batches/ERROR'
};

export const setBatchesLoading = (toggle: boolean): any => {
    return { type: batchActions.SET_LOADING, toggle };
};

export const selectBatch = (selectedBatch: any): any => {
    return { type: batchActions.SELECT, selectedBatch };
};

export const forceRenderStatus = (batchId: string, status: string): any => {
    return { type: batchActions.FORCE_RENDER_STATUS, batchId, forcedRenderStatus: status };
};

export const getBatches = (): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            const {
                story,
                batchesList: { filters, selected }
            } = getStore();
            let activeBatch: any;

            dispatch(setBatchesLoading(true));
            api.getBatches(story.id, filters)
                .then((batches: any) => {
                    if (Object.keys(selected).length === 1) {
                        activeBatch = batches.batches.find((b: any) => b.id === selected.id);

                        if (typeof activeBatch === 'object') {
                            dispatch(selectBatch(activeBatch));
                        }
                    }
                    dispatch({ type: batchActions.GET, batches });
                    dispatch(setBatchesLoading(false));
                    resolve(batches);
                })
                .catch((e: Error) => {
                    dispatch(setBatchesLoading(false));
                    reject(e);
                });
        });
    };
};

export const createFreshBatch = (batchName: string): any => {
    return (dispatch, getStore): Promise<any> => {
        if (batchName.length > 0) {
            return new Promise<void>((resolve, reject) => {
                const { story } = getStore();
                api.createFreshBatch(story.id, batchName)
                    .then((batch: any) => {
                        dispatch(getBatches());
                        dispatch(selectBatch(batch));
                        resolve(batch);
                    })
                    .catch((e: Error) => {
                        reject(e);
                    });
            });
        }
    };
};

export default batchActions;
