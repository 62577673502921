export const VIEWER_TYPES: any = {
    TIMELINE_PREVIEW: 'timeline_preview',
    STATIC_COMPOSITION: 'static_composition',
    IMAGE_STORY_PREVIEW: 'image_story_preview',
    STORY_PREVIEW: 'story_preview',
    COMPOSITION_PREVIEW: 'composition_preview',
    CUT_PREVIEW: 'cut_preview',
    BASE_VIDEO: 'base_video',
    JOB_LOG: 'job_log',
    ERROR_VIEWER: 'error_viewer',
    NEW_COMPOSITION: 'new_composition'
};

export const VIEWER_LABELS: any = {
    [VIEWER_TYPES.TIMELINE_PREVIEW]: 'Timeline',
    [VIEWER_TYPES.STORY_PREVIEW]: 'Story Preview',
    [VIEWER_TYPES.IMAGE_STORY_PREVIEW]: 'Story Preview',
    [VIEWER_TYPES.STATIC_COMPOSITION]: 'Static Composition',
    [VIEWER_TYPES.ERROR_VIEWER]: 'Error Review'
};
