import store from '../redux/store';
import { log, clear } from '../redux/actions/notifications';
import { NOTIFICATION_TYPES } from '../constants/story';

export const logNotification = (text, button?): void => {
    store.dispatch(log(text, NOTIFICATION_TYPES.INFO, button));
};

export const logError = (text, button?): void => {
    store.dispatch(log(text, NOTIFICATION_TYPES.ERROR, button));
};

export const logWarning = (text, button?): void => {
    store.dispatch(log(text, NOTIFICATION_TYPES.WARNING, button));
};

export const clearNotifications = (): void => {
    store.dispatch(clear());
};
