import { service_api } from '../../constants/app';

export const getQueues = () => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            service_api
                .getQueues()
                .then((queues: any[]) => {
                    dispatch({ type: actions.RECEIVE, queues });
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };
};

const actions = {
    RECEIVE: 'queues/GET'
};

export default actions;
