import actions from '../actions/editor';
import compActions from '../actions/compositions';
import storyActions from '../actions/story';

import { localStorageLoad, localStorageSave } from '../../util/general';
import { CODE_EDITOR_TABS, LOWER_PANELS, PROJECT_SETTINGS_TABS } from '../../constants/editor';

export interface IEditor {
    showCopyPropIds: boolean;
    settingsModalOpen: boolean;
    newStoryModalOpen: boolean;
    publishWizardOpen: boolean;
    varsOpen: boolean;
    activeSettingsTab: string;
    postSettingsAction: string;
    loading: boolean;
    blockingJobRunning: boolean;
    varsDisabled: boolean;
    varsLoading: boolean;
    sortVars: boolean;
    tabMode: boolean;
    activeCodeEditorTab: string;
    expandTags: boolean;
    showVariables: boolean;
    assetColumnsFilter: {
        tags: boolean;
        duration: boolean;
        rate: boolean;
        height: boolean;
        width: boolean;
        date_updated: boolean;
        last_updated_by: boolean;
        date_created: boolean;
    };
    fromCrM: boolean;
    assetUploads: {
        tagByDelimiter: boolean;
        delimiter: string;
        tagManually: boolean;
        manualTags: string[];
        tagByFileName: boolean;
        assignToStory: boolean;
    };
    autoRender: boolean;
    fastRender: boolean;
    playbackRate: number;
    activeLowerPanel: string;
    activeDatasetId: string;
    inventoryOverrides: object;
    publishDataset: boolean;
    reHydrateAssetTable: boolean;
    expandPanel: boolean;
    openFilteredList: boolean;
    refreshHTMLEditor: boolean;
    showUploadsMenu: boolean;
    showUploadsDropdown: boolean;
}

const defaultState: IEditor = {
    showCopyPropIds: false,
    fromCrM: true,
    loading: true,
    settingsModalOpen: false,
    newStoryModalOpen: false,
    publishWizardOpen: false,
    varsOpen: true,
    blockingJobRunning: false,
    postSettingsAction: null,
    activeSettingsTab: PROJECT_SETTINGS_TABS.CONFIRM_SETTINGS,
    varsDisabled: false,
    varsLoading: false,
    sortVars: true,
    tabMode: false,
    activeCodeEditorTab: CODE_EDITOR_TABS.CODE,
    expandTags: false,
    showVariables: false,
    autoRender: false,
    fastRender: false,
    playbackRate: 1,
    reHydrateAssetTable: false,
    assetColumnsFilter: {
        tags: true,
        duration: true,
        rate: false,
        height: false,
        width: false,
        date_updated: true,
        last_updated_by: false,
        date_created: false
    },
    assetUploads: {
        tagByDelimiter: true,
        delimiter: '_',
        tagManually: false,
        manualTags: [],
        tagByFileName: false,
        assignToStory: true
    },
    activeLowerPanel: LOWER_PANELS.TIMELINE,
    activeDatasetId: null,
    inventoryOverrides: null,
    publishDataset: false,
    expandPanel: false,
    openFilteredList: false,
    refreshHTMLEditor: false,
    showUploadsMenu: true,
    showUploadsDropdown: false
};

const storageKey = 'imposium-editor-state';

const stateFromStorage = localStorageLoad(storageKey);

// force loading initially
if (stateFromStorage) {
    stateFromStorage.autoRender = false;
    stateFromStorage.showCopyPropIds = false;
    stateFromStorage.fastRender = false;
    stateFromStorage.playbackRate = 1;
    stateFromStorage.loading = true;
    stateFromStorage.postSettingsAction = null;
    stateFromStorage.inventoryOverrides = null;
    stateFromStorage.scaleModifier = null;
    stateFromStorage.reHydrateAssetTable = false;
    stateFromStorage.expandPanel = false;
    stateFromStorage.openFilteredList = false;
    stateFromStorage.publishWizardOpen = false;
    stateFromStorage.settingsModalOpen = false;
    stateFromStorage.newStoryModalOpen = false;
    stateFromStorage.showUploadsDropdown = false;
    if (stateFromStorage.activeSettingsTab === PROJECT_SETTINGS_TABS.CONFIRM_SETTINGS) {
        stateFromStorage.activeSettingsTab = PROJECT_SETTINGS_TABS.PROJECT;
    }
    if (!stateFromStorage.assetColumnsFilter) {
        stateFromStorage.assetColumnsFilter = {
            tags: true,
            duration: true,
            rate: true,
            height: true,
            width: true,
            date_updated: true,
            last_updated_by: false,
            date_created: false
        };
    }
    if (!stateFromStorage.assetUploads) {
        stateFromStorage.assetUploads = {
            tagByDelimiter: true,
            delimiter: '_',
            tagManually: false,
            manualTags: [],
            tagByFileName: false,
            assignToStory: true
        };
    }
}

const initialState = stateFromStorage || defaultState;

const editor = (state = initialState, action): any => {
    let newState: IEditor;
    switch (action.type) {
        case actions.UPDATE:
            newState = { ...state, ...action.config };
            localStorageSave(storageKey, newState);
            return newState;

        case compActions.SET_COMPOSITION:
            newState = { ...state };
            localStorageSave(storageKey, newState);
            return newState;

        case storyActions.SET_STORY:
            newState = { ...state };
            if (action.data.creativeId && action.data.creativeLibraryId) {
                newState.fromCrM = true;
            } else {
                newState.fromCrM = false;
            }
            return newState;

        default:
            return state;
    }
};

export default editor;
