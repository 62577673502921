import editor from './editor';
import notifications from './notifications';
import story from './story';
import project from './project';
import changes from './changes';
import compositions from './compositions';
import errors from './errors';
import batchesList from './batches';
import batchJobs from './batch-jobs';
import assetCache from './asset-cache';
import timeline from './timeline';
import layerSources from './layerSources';
import layerErrors from './layerErrors';
import compActions from '../actions/compositions';
import datasetActions from '../actions/datasets';
import asset from '../reducers/asset';
import undoable, { includeAction } from 'redux-undo';
import { access, auth, publish } from '@imposium-hub/components';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import {
    assetList,
    assetFilters,
    selectedAssets,
    assetUploads,
    assetTags,
    activeBatch
} from '@imposium-hub/components';
import queues from './queues';
import datasets from './datasets';
import layerCopy from './layerCopy';

const undoableCompositionActions: any = includeAction([
    compActions.EDIT_COMPOSITION,
    compActions.UPDATE_LAYER,
    compActions.MOVE_LAYER,
    compActions.DELETE_LAYER,
    compActions.DUPLICATE_LAYER,
    compActions.ADD_LAYER,
    compActions.UPDATE_ANCHOR,
    compActions.DELETE_ANCHOR,
    compActions.SHIFT_LAYERS
]);

const undoableDatasetActions: any = includeAction([
    datasetActions.SET_ACTIVE_DATASET_DATA,
    datasetActions.ADD_ROW,
    datasetActions.ADD_COLUMN,
    datasetActions.DELETE_ROWS,
    datasetActions.DELETE_COLUMNS
]);

const rootReducer = combineReducers({
    story,
    auth,
    asset,
    access,
    editor,
    project,
    datasets: undoable(datasets, {
        filter: undoableDatasetActions,
        syncFilter: true
    }),
    layerSources,
    layerErrors,
    publish,
    timeline,
    compositions: undoable(compositions, {
        filter: undoableCompositionActions,
        limit: 100,
        ignoreInitialState: true,
        syncFilter: true
    }),
    changes,
    assetList,
    assetCache,
    assetFilters,
    assetUploads,
    assetTags,
    notifications,
    selectedAssets,
    errors,
    batchesList,
    batchJobs,
    activeBatch,
    queues,
    layerCopy,
    routing: routerReducer
});

export default rootReducer;
