import { Modal } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import store from '../redux/store';
import { HEADER_HEIGHT } from '../constants/editor';
import { updateEditorConfig } from '../redux/actions/editor';
import { api } from '../constants/app';
import { setLayerErrors } from '../redux/actions/layerErrors';

const filterListTable = () => {
    const state = store.getState();
    const {
        layerErrors,
        timeline: { activeTimelineLayer: id }
    } = state;
    if (layerErrors[id] && layerErrors[id]?.filteredList?.length > 0) {
        const { filteredList } = layerErrors[id];
        return (
            <table className='filtered'>
                <thead>
                    <tr className='filteredName'>
                        <th className='name'>{copy.layerConfig.filterAssetName}</th>
                        <th className='diff'>{copy.layerConfig.missingFrames}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredList.map((m) => {
                        return (
                            <tr
                                className='filterItem'
                                key={m.name}>
                                <td className='name'>{m.name}</td>
                                <td className='diff'>{Math.floor(m.diff)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
};

export const checkAvailableAssets = (asset_tags, layerType, storyId, duration, layerId) => {
    const addTag = (t) => {
        if (!tagStr) {
            tagStr = t;
        } else {
            tagStr += `,${t}`;
        }
    };

    let tagStr = '';

    for (const tag of asset_tags) {
        if (tag.type === 'text') {
            addTag(tag.value);
        }
    }

    if (tagStr) {
        api.getAssets({ tags: tagStr, type: layerType }, storyId).then((assetList) => {
            const totalAssets = assetList.assets ? assetList.assets.length : 0;
            const filteredList = [];
            const filteredAssets = assetList.assets;
            filteredAssets
                .filter((f) => f.duration < duration)
                .map((m) => {
                    const diff = duration - m.frame_count;
                    if (diff > 0) {
                        const obj = {
                            name: m.name,
                            diff
                        };
                        filteredList.push(obj);
                    }
                });

            store.dispatch(
                setLayerErrors(layerId, {
                    filteredAssetsLength: totalAssets,
                    filteredList
                })
            );
        });
    } else {
        store.dispatch(
            setLayerErrors(layerId, {
                filteredAssetsLength: 0
            })
        );
    }
};

export const FilteredListModal = () => {
    const state = store.getState();
    const {
        editor: { openFilteredList }
    } = state;

    return (
        <Modal
            onRequestClose={() =>
                store.dispatch(
                    updateEditorConfig({
                        openFilteredList: !openFilteredList
                    })
                )
            }
            wrapperStyle={{
                top: HEADER_HEIGHT,
                left: '0px',
                width: '100%',
                height: `calc(100% - ${HEADER_HEIGHT}px)`
            }}
            style={{
                width: '600px',
                height: '600px',
                top: '10%',
                left: 'calc((100% - 300px) / 2)',
                overflow: 'auto'
            }}
            isOpen={openFilteredList}>
            {filterListTable()}
        </Modal>
    );
};
