import { doAssetTableHydration, updateAssetData } from '@imposium-hub/components';
import { api } from '../../constants/app';
import {
    CODE_EDITOR_TABS,
    FORCE_UPDATE_HTML_ASSET,
    LOWER_PANELS,
    PROJECT_SETTINGS_TABS
} from '../../constants/editor';
import { IAct } from '../../constants/snippets';
import { getFirstAct, parseInventoryFromVariables, newHTMLAsset } from '../../util/story';
import { IAssetViewConfig } from './story';
import { NOTIFICATION_TYPES } from '../../constants/story';
import { log } from './notifications';
import { assets as copy } from '../../constants/copy';
import timeline from './timeline';

const editor: any = {
    UPDATE: 'editor/UPDATE',
    GET: 'assetList/GET',
    ADD_VIEWER: 'viewer/ADD'
};

interface HTMLAssets {
    devConfig: {
        type: string;
        fps: number;
        frames: number;
        frame: number;
        width: number;
        height: number;
        background_color: string;
        inventory: any;
    };
    javascript: string;
    html: string;
    css: string;
}

export const updateEditorConfig = (config): any => {
    return (dispatch) => {
        dispatch({ type: editor.UPDATE, config });

        // If we're expanding the assets panel, deselect any timeline layers
        if (config.expandPanel) {
            dispatch({
                type: timeline.UPDATE,
                config: {
                    activeMultiSelectLayers: [],
                    activeTimelineLayer: null
                }
            });
        }
    };
};

export const resetEditorConfig = (): any => {
    return (dispatch) => {
        dispatch({
            type: editor.UPDATE,
            config: {
                settingsModalOpen: false,
                newStoryModalOpen: false,
                unsavedChanges: false,
                varsOpen: false,
                postSettingsAction: null,
                inventoryOverrides: null,
                activeSettingsTab: PROJECT_SETTINGS_TABS.PROJECT,
                varsLoading: false,
                sortVars: true,
                tabMode: false,
                activeCodeEditorTab: CODE_EDITOR_TABS.CODE,
                expandTags: false,
                assetColumnsFilter: {
                    tags: true,
                    duration: true,
                    date_updated: true
                },
                autoRender: false,
                activeLowerPanel: LOWER_PANELS.TIMELINE,
                fastRender: false,
                openFilteredList: false,
                publishWizardOpen: false,
                showVariables: false
            }
        });
    };
};

export const getAssetMapHandler = async (storyId) => {
    return await api.getAssetMap(storyId);
};

export const uploadHTMLAsset = (event: any, filename: string): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            const {
                project: { storyId, actId },
                story,
                story: { acts }
            } = getStore();
            const act: IAct = actId !== '' ? acts[actId] : getFirstAct(story);
            const inventory = parseInventoryFromVariables(act.inventory);
            void getAssetMapHandler(storyId).then((map) => {
                let HTMLAsset: HTMLAssets = newHTMLAsset(inventory, map);
                if (event) {
                    const obj = JSON.parse(event.target.result);
                    if ('html' in obj && 'javascript' in obj && 'css' in obj) {
                        HTMLAsset = { ...HTMLAsset, ...obj };
                        const name = filename.split('.')[0];
                        api.createHTMLAsset(storyId, JSON.stringify(HTMLAsset), name)
                            .then(() => {
                                dispatch(doAssetTableHydration(api, storyId));
                                resolve();
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    } else {
                        dispatch(log(`${copy.invalidHTMLAsset}`, NOTIFICATION_TYPES.ERROR));
                        reject();
                    }
                }
            });
        });
    };
};

export const replaceHTMLAsset = (event: any, assetId: string): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            const {
                project: { storyId }
            } = getStore();

            if (event) {
                const obj = JSON.parse(event.target.result);

                if ('html' in obj && 'javascript' in obj && 'css' in obj) {
                    api.getAssetItem(assetId)
                        .then((asset: any) => {
                            const type = typeof asset.data;

                            if (type === 'string') {
                                asset.data = JSON.parse(asset.data);
                            }

                            const replacedData = { ...asset.data, ...obj };

                            dispatch(updateAssetData(api, asset.id, replacedData)).then(() => {
                                // dispatch the event to drigger the update of the HTML editor
                                const e = new CustomEvent(FORCE_UPDATE_HTML_ASSET, {});
                                document.dispatchEvent(e);
                                dispatch(doAssetTableHydration(api, storyId));
                            });
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                } else {
                    dispatch(log(`${copy.invalidHTMLAsset}`, NOTIFICATION_TYPES.ERROR));
                    resolve();
                }
            }
        });
    };
};

export const createNewHTMLAsset = (): any => {
    return (dispatch, getStore) => {
        return new Promise<any>((resolve, reject) => {
            const {
                assetList,
                project: { storyId, actId },
                story,
                story: { acts }
            } = getStore();

            const act: IAct = actId !== '' ? acts[actId] : getFirstAct(story);
            const inventory = parseInventoryFromVariables(act.inventory);

            void getAssetMapHandler(storyId).then((map) => {
                const HTMLAsset: HTMLAssets = newHTMLAsset(inventory, map);

                api.createHTMLAsset(storyId, JSON.stringify(HTMLAsset))
                    .then((res: any) => {
                        const asset: any = res[0];
                        const newList = { ...assetList };
                        const assets = [...newList.assets];

                        assets.unshift(asset);
                        newList.assets = assets;
                        newList.asset_count = newList.asset_count + 1;

                        dispatch({
                            type: editor.GET,
                            assetList: newList
                        });

                        const { id, name, type } = asset;
                        const tabLabel = `Asset: ${name || id}`;
                        const viewConfig: IAssetViewConfig = { id, label: tabLabel, asset, type };

                        dispatch({ type: editor.ADD_VIEWER, viewConfig });

                        resolve(asset);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        });
    };
};

export default editor;
