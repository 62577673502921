import actions from '../actions/changes';

export interface IChanges {
    unsaved: boolean;
    comps: any;
    templates: any;
    loading: boolean;
}

const initialState: IChanges = {
    unsaved: false,
    comps: [],
    templates: [],
    loading: false
};

const editor = (state = initialState, action): any => {
    let newState: IChanges;

    switch (action.type) {
        case actions.UPDATE:
            newState = { ...state, ...action.config };
            return newState;

        case actions.UPDATE_COMP:
            newState = { ...state };
            newState.unsaved = true;
            if (newState.comps.indexOf(action.id) === -1) {
                newState.comps.push(action.id);
            }
            return newState;

        case actions.UPDATE_TEMPLATE:
            newState = { ...state };
            newState.unsaved = true;
            if (newState.templates.indexOf(action.id) === -1) {
                newState.templates.push(action.id);
            }
            return newState;

        default:
            return state;
    }
};

export default editor;
