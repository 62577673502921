const layerCopy: any = {
    COPY_LAYER: 'layerCopy/COPY_LAYER',
    CLEAR_COPY: 'layerCopy/CLEAR_COPY',
    CAN_COPY: 'layerCopy/CAN_COPY'
};

export const copyLayer = (layer): any => {
    return (dispatch) => {
        return new Promise<void>((resolve) => {
            dispatch({ type: layerCopy.COPY_LAYER, layer });
            resolve();
        });
    };
};

// export const setCanCopy = (canCopy): any => {
//     return (dispatch) => {
//         return new Promise<void>((resolve) => {
//             dispatch({ type: layerCopy.CAN_COPY, canCopy });
//             resolve();
//         });
//     };
// };

export const clearCopy = (): any => {
    return (dispatch) => {
        dispatch({ type: layerCopy.CLEAR_COPY });
    };
};

export default layerCopy;
