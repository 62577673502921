import { EASE_TYPES } from '../constants/story';

// t: current time, b: beginning value, c: change in value, d: duration

export const EASING_FUNCTIONS = {
    [EASE_TYPES.LINEAR]: (t, b, c, d) => {
        return c * (t / d);
    },

    [EASE_TYPES.QUAD_OUT]: (t, b, c, d) => {
        return -c * (t /= d) * (t - 2) + b;
    },

    [EASE_TYPES.QUAD_IN]: (t, b, c, d) => {
        return c * (t /= d) * t + b;
    },

    [EASE_TYPES.QUAD_INOUT]: (t, b, c, d) => {
        if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
        return (-c / 2) * (--t * (t - 2) - 1) + b;
    },

    [EASE_TYPES.QUINT_OUT]: (t, b, c, d) => {
        return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
    },

    [EASE_TYPES.QUINT_IN]: (t, b, c, d) => {
        return c * (t /= d) * t * t * t * t + b;
    },

    [EASE_TYPES.QUINT_INOUT]: (t, b, c, d) => {
        if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t * t + b;
        return (c / 2) * ((t -= 2) * t * t * t * t + 2) + b;
    },

    [EASE_TYPES.CUBIC_IN]: (t, b, c, d) => {
        return c * (t /= d) * t * t + b;
    },

    [EASE_TYPES.CUBIC_OUT]: (t, b, c, d) => {
        return c * ((t = t / d - 1) * t * t + 1) + b;
    },

    [EASE_TYPES.CUBIC_INOUT]: (t, b, c, d) => {
        if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b;
        return (c / 2) * ((t -= 2) * t * t + 2) + b;
    },

    [EASE_TYPES.QUART_IN]: (t, b, c, d) => {
        return c * (t /= d) * t * t * t + b;
    },

    [EASE_TYPES.QUART_OUT]: (t, b, c, d) => {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
    },

    [EASE_TYPES.QUART_INOUT]: (t, b, c, d) => {
        if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b;
        return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
    },

    [EASE_TYPES.SINE_IN]: (t, b, c, d) => {
        return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b;
    },

    [EASE_TYPES.SINE_OUT]: (t, b, c, d) => {
        return c * Math.sin((t / d) * (Math.PI / 2)) + b;
    },

    [EASE_TYPES.SINE_INOUT]: (t, b, c, d) => {
        return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
    },

    [EASE_TYPES.EXPO_IN]: (t, b, c, d) => {
        return t === 0 ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
    },

    [EASE_TYPES.EXPO_OUT]: (t, b, c, d) => {
        return t === d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
    },

    [EASE_TYPES.EXPO_INOUT]: (t, b, c, d) => {
        if (t === 0) return b;
        if (t === d) return b + c;
        if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
        return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
    },

    [EASE_TYPES.CIRC_IN]: (t, b, c, d) => {
        return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
    },

    [EASE_TYPES.CIRC_OUT]: (t, b, c, d) => {
        return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
    },

    [EASE_TYPES.CIRC_INOUT]: (t, b, c, d) => {
        if ((t /= d / 2) < 1) return (-c / 2) * (Math.sqrt(1 - t * t) - 1) + b;
        return (c / 2) * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
    }
};
