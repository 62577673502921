const layerErrors: any = {
    SET_ERRORS: 'layerErrors/SET_ERRORS'
};

export const setLayerErrors = (layerID, errors): any => {
    return (dispatch) => {
        dispatch({ type: layerErrors.SET_ERRORS, id: layerID, errors });
    };
};

export default layerErrors;
