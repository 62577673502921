const layerSources: any = {
    SET_SOURCE: 'layerSources/SET_SOURCE',
    CLEAR_SOURCE: 'layerSources/CLEAR_SOURCE',
    CLEAR_ALL: 'layerSources/CLEAR_ALL'
};

export const clearAllLayerSources = (): any => {
    return (dispatch) => {
        dispatch({ type: layerSources.CLEAR_ALL });
    };
};

export const setLayerSource = (layerID, layerSource): any => {
    return (dispatch) => {
        return new Promise<void>((resolve) => {
            dispatch({ type: layerSources.SET_SOURCE, id: layerID, source: layerSource });
            resolve();
        });
    };
};

export const clearLayerSource = (id): any => {
    return (dispatch) => {
        dispatch({ type: layerSources.CLEAR_SOURCE, id });
    };
};

export default layerSources;
