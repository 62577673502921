export const DEFAULT_FONTS = [
    {
        name: 'Arial',
        family: 'Arial',
        file: 'Arial.ttf',
        weight: 400
    },
    {
        name: 'Abril Fatface',
        family: 'Abril Fatface',
        file: 'AbrilFatface-Regular.ttf',
        weight: 400
    },
    {
        name: 'Alfa Slab One',
        family: 'Alfa Slab One',
        file: 'AlfaSlabOne-Regular.ttf',
        weight: 400
    },
    {
        name: 'Amatic SC',
        family: 'Amatic SC',
        file: 'AmaticSC-Regular.ttf',
        weight: 400
    },
    {
        name: 'Anton',
        family: 'Anton',
        file: 'Anton-Regular.ttf',
        weight: 400
    },
    {
        name: 'Assistant',
        family: 'Assistant',
        file: 'Assistant-VariableFont_wght.ttf',
        weight: 400
    },
    {
        name: 'Assistant Bold',
        family: 'Assistant',
        file: 'Assistant-VariableFont_wght.ttf',
        weight: 700
    },
    {
        name: 'Bangers',
        family: 'Bangers',
        file: 'Bangers-Regular.ttf',
        weight: 400
    },
    {
        name: 'Cabin Sketch',
        family: 'Cabin Sketch',
        file: 'CabinSketch-Regular.ttf',
        weight: 400
    },
    {
        name: 'Caveat Brush',
        family: 'Caveat Brush',
        file: 'CaveatBrush-Regular.ttf',
        weight: 400
    },
    {
        name: 'Creepster',
        family: 'Creepster',
        file: 'Creepster-Regular.ttf',
        weight: 400
    },
    {
        name: 'Crete Round',
        family: 'Crete Round',
        file: 'CreteRound-Regular.ttf',
        weight: 400
    },
    {
        name: 'Fjalla One',
        family: 'Fjalla One',
        file: 'FjallaOne-Regular.ttf',
        weight: 400
    },
    {
        name: 'Fredericka the Great',
        family: 'Fredericka the Great',
        file: 'FrederickatheGreat-Regular.ttf',
        weight: 400
    },
    {
        name: 'Gentium Basic',
        family: 'Gentium Basic',
        file: 'GentiumBasic-Regular.ttf',
        weight: 400
    },
    {
        name: 'Lato',
        family: 'Lato',
        file: 'Lato-Regular.ttf',
        weight: 400
    },
    {
        name: 'Lobster',
        family: 'Lobster',
        file: 'Lobster-Regular.ttf',
        weight: 400
    },
    {
        name: 'Love Ya Like A Sister',
        family: 'Love Ya Like A Sister',
        file: 'LoveYaLikeASister-Regular.ttf',
        weight: 400
    },
    {
        name: 'Monoton',
        family: 'Monoton',
        file: 'Monoton-Regular.ttf',
        weight: 400
    },
    {
        name: 'Merriweather',
        family: 'Merriweather',
        file: 'Merriweather-Regular.ttf',
        weight: 400
    },
    {
        name: 'Montserrat',
        family: 'Montserrat',
        file: 'Montserrat-VariableFont_wght.ttf',
        weight: 400
    },
    {
        name: 'Montserrat Bold',
        family: 'Montserrat',
        file: 'Montserrat-VariableFont_wght.ttf',
        weight: 700
    },
    {
        name: 'Montserrat ExtraBold',
        family: 'Montserrat',
        file: 'Montserrat-VariableFont_wght.ttf',
        weight: 800
    },
    {
        name: 'Noto Sans',
        family: 'Noto Sans',
        file: 'NotoSans-Regular.ttf',
        weight: 400
    },
    {
        name: 'Noto Serif',
        family: 'Noto Serif',
        file: 'NotoSerif-Regular.ttf',
        weight: 400
    },
    {
        name: 'Open Sans',
        family: 'Open Sans',
        file: 'OpenSans-VariableFont_wdth,wght.ttf',
        weight: 400
    },
    {
        name: 'Open Sans Condensed',
        family: 'Open Sans Condensed',
        file: 'OpenSansCondensed-Light.ttf',
        weight: 300
    },
    {
        name: 'Oswald',
        family: 'Oswald',
        file: 'Oswald-VariableFont_wght.ttf',
        weight: 400
    },
    {
        name: 'Patua One',
        family: 'Patua One',
        file: 'PatuaOne-Regular.ttf',
        weight: 400
    },
    {
        name: 'Playfair Display',
        family: 'Playfair Display',
        file: 'PlayfairDisplay-VariableFont_wght.ttf',
        weight: 400
    },
    {
        name: 'Poor Story',
        family: 'Poor Story',
        file: 'PoorStory-Regular.ttf',
        weight: 400
    },
    {
        name: 'Poppins',
        family: 'Poppins',
        file: 'Poppins-Regular.ttf',
        weight: 400
    },
    {
        name: 'Poppins Bold',
        family: 'Poppins',
        file: 'Poppins-Bold.ttf',
        weight: 700
    },
    {
        name: 'PT Sans Narrow',
        family: 'PT Sans Narrow',
        file: 'PTSansNarrow-Regular.ttf',
        weight: 400
    },
    {
        name: 'Quicksand',
        family: 'Quicksand',
        file: 'Quicksand-VariableFont_wght.ttf',
        weight: 400
    },
    {
        name: 'Raleway',
        family: 'Raleway',
        file: 'Raleway-VariableFont_wght.ttf',
        weight: 400
    },
    {
        name: 'Raleway Bold',
        family: 'Raleway',
        file: 'Raleway-VariableFont_wght.ttf',
        weight: 700
    },
    {
        name: 'Raleway Black',
        family: 'Raleway',
        file: 'Raleway-VariableFont_wght.ttf',
        weight: 900
    },
    {
        name: 'Roboto',
        family: 'Roboto',
        file: 'Roboto-Regular.ttf',
        weight: 400
    },
    {
        name: 'Roboto Condensed',
        family: 'Roboto Condensed',
        file: 'RobotoCondensed-Regular.ttf',
        weight: 400
    },
    {
        name: 'Rubik',
        family: 'Rubik',
        file: 'Rubik-VariableFont_wght.ttf',
        weight: 400
    },
    {
        name: 'Rubik Bold',
        family: 'Rubik',
        file: 'Rubik-VariableFont_wght.ttf',
        weight: 700
    },
    // {
    //     name: 'Rubik Black',
    //     family: 'Rubik',
    //     file: 'Rubik-VariableFont_wght.ttf',
    //     weight: 900,
    // },
    {
        name: 'Shrikhand',
        family: 'Shrikhand',
        file: 'Shrikhand-Regular.ttf',
        weight: 400
    },
    {
        name: 'Source Sans Pro',
        family: 'Source Sans Pro',
        file: 'SourceSansPro-Regular.ttf',
        weight: 400
    },
    {
        name: 'Suez One',
        family: 'Suez One',
        file: 'SuezOne-Regular.ttf',
        weight: 400
    },
    {
        name: 'Titillium Web',
        family: 'Titillium Web',
        file: 'TitilliumWeb-Regular.ttf',
        weight: 400
    },
    {
        name: 'Titillium Web Black',
        family: 'Titillium Web',
        file: 'TitilliumWeb-Black.ttf',
        weight: 900
    },
    {
        name: 'Varela Round',
        family: 'Varela Round',
        file: 'VarelaRound-Regular.ttf',
        weight: 400
    }
];
