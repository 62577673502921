import { ASSET_PENDING, ASSET_SUCCESS, ASSET_ERROR, JOB_PENDING } from '../../constants/asset';

const initialState = {
    pendingAssets: [],
    loading: false,
    error: null
};

const assetReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSET_PENDING:
            return {
                ...state,
                loading: true
            };
        case JOB_PENDING:
            return {
                ...state,
                // pendingCopy: state.pendingCopy.filter(copy => copy.id !== action.copyResp.id),
                pendingAssets: [...state.pendingAssets, action.copyResp]
            };
        case ASSET_SUCCESS:
            return {
                ...state,
                pendingAssets: state.pendingAssets.filter(
                    (asset) => asset.job_id !== action.jobResp.id
                ),
                loading: false
            };
        case ASSET_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        default:
            return state;
    }
};

export default assetReducer;
