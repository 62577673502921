import { COMPOSITION_LAYER_TYPES } from './story';

export const DATASET_ERRORS = {
    WRONG_TYPE: 'WRONG_TYPE',
    BROKEN_URL: 'BROKEN_URL',
    MISSING_REQUIRED_VALUE: 'MISSING_REQUIRED_VALUE',
    NO_ASSET_FOUND: 'NO_ASSET_FOUND',
    INCORRECT_VALUE_ENUM: 'INCORRECT_VALUE_ENUM',
    ASSET_TOO_SHORT: 'ASSET_TOO_SHORT'
};

export const SWAPPABLE_LAYER_TYPES = [
    COMPOSITION_LAYER_TYPES.AUDIO,
    COMPOSITION_LAYER_TYPES.VIDEO,
    COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION,
    COMPOSITION_LAYER_TYPES.IMAGE,
    COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE,
    COMPOSITION_LAYER_TYPES.TEMPLATE
];

export const ASSET_LAYER_TYPES = [
    COMPOSITION_LAYER_TYPES.VIDEO,
    COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE,
    COMPOSITION_LAYER_TYPES.IMAGE,
    COMPOSITION_LAYER_TYPES.AUDIO,
    COMPOSITION_LAYER_TYPES.TEMPLATE
];

export const DEFAULT_EXTENSION_MAP = {
    [COMPOSITION_LAYER_TYPES.VIDEO]: 'mp4',
    [COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE]: 'zip',
    [COMPOSITION_LAYER_TYPES.IMAGE]: 'png',
    [COMPOSITION_LAYER_TYPES.AUDIO]: 'mp3',
    [COMPOSITION_LAYER_TYPES.TEMPLATE]: 'zip'
};

export const MAX_DATASET_LENGTH: number = 1000;
