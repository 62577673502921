export const FRAME_WIDTH: number = 60;

export const ROW_HEIGHT: number = 20;

export const LAYER_ROW_HEIGHT: number = 25;

export const LAYER_EXPANDED_ROW_HEIGHT: number = 159;

export const LAYER_SELECT_BOX_PADDING: number = 1;

export const LAYER_PADDING: number = 2;

export const CUT_HANDLE_WIDTH: number = 10;

export const CUT_HANDLE_BREAKPOINT: number = 10;

export const PLAYHEAD_SCROLL_THRESHOLD: number = 200;

export const FRAMES_PER_SCROLL: number = 1;

export const PLAYHED_SCROLL_TIMEOUT: number = 50;

export const LAYER_ANCHOR_DRAG_HANDLE_SIZE: number = 12;

export const LAYER_ANCHOR_POINT_DRAG_HANDLE_SIZE: number = 30;

export const FRAME_SEEK_OFFSET = 0.9;

export const DRAGGABLE_ITEM_TYPES = {
    HANDLE: 'handle',
    BAR: 'bar'
};

export const MIN_LABEL_WIDTH = 25;

export const LAYER_DND_TYPES = {
    LAYER_NAME: 'LAYER_NAME',
    ANCHOR: 'ANCHOR'
};

export const ASSET_DND_TYPES = {
    ASSET_NAME: 'asset_name'
};

export const KEYFRAME_SIZE: number = 8;

export const KEYFRAME_CONTROLLER_TYPES = {
    NUMBER: 'NUMBER',
    COMPOUND: 'COMPOUND'
};
