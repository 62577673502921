import {
    SOURCE_TYPES,
    OVERLAY_TYPES,
    API_REQUEST_TYPES,
    OVERLAY_ENGINE_TYPES,
    SCENE_TYPES,
    ENCODING_SETTING_PRESETS,
    AUDIO_SOURCES,
    DEFAULT_FONT,
    DEFAULT_FONT_WEIGHT,
    VIDEO_FORMATS,
    ENCODING_SETTING_VIDEO_DEFAULTS,
    ENCODING_SETTING_AUDIO_DEFAULTS,
    RESIZE_TYPES,
    LAYER_KEYFRAME_INTERPOLATION_TYPES,
    ORIGINS,
    LAYOUT_UNITS,
    TTS_SERVICES,
    AUDIO_FORMATS,
    AUDIO_BITRATES,
    AUDIO_SAMPLE_RATES,
    AUDIO_CHANNEL_OPTIONS,
    TTS_SERVICE_DEFAULTS
} from '../constants/story';
import { VIEWER_LABELS, VIEWER_TYPES } from './viewer';

export interface IStory {
    name: string;
    id: string;
    enabled: boolean;
    metadata: IMetadata;
    moderationType: string;
    moderationApprovedScene: string;
    moderationRejectedScene: string;
    moderationAwaitApproval: boolean;
    acts: object;
    discardUgc: boolean;
    gaTrackingId: string;
    renderedBucket?: string;
    uploadsBucket?: string;
    whitelistedUrls: string;
    disabled: boolean;
    variableAssets: boolean;
    viewer?: IViewer;
    queueId: string;
    creativeLibraryId?: number;
    creativeId?: number;
}

export interface IMetadata {
    title: string;
    description: string;
    twitterHandle: string;
    fbAppId: string;
    locale: string;
}

export const NEW_METADATA: IMetadata = {
    title: 'Imposium Video Player',
    description: 'Dynamic video player powered by Imposium',
    twitterHandle: '',
    fbAppId: '',
    locale: 'en_US'
};

export interface IViewer {
    activeViewer: string;
    tabs: IViewerTab[];
}

export interface IViewerTab {
    type: string;
    id: string;
    label: string;
    jobId?: string;
    asset?: any;
    url?: string;
    compositionId?: string;
}

export const NEW_VIDEO_DEFAULT_VIEWER: IViewer = {
    activeViewer: VIEWER_TYPES.TIMELINE_PREVIEW,
    tabs: [
        {
            type: VIEWER_TYPES.TIMELINE_PREVIEW,
            id: VIEWER_TYPES.TIMELINE_PREVIEW,
            label: VIEWER_LABELS[VIEWER_TYPES.TIMELINE_PREVIEW]
        }
    ]
};

export const NEW_EMPTY_DEFAULT_VIEWER: IViewer = {
    activeViewer: null,
    tabs: []
};

export interface IComposition {
    id?: string;
    cuts: ICut[];
    color?: string;
    frames: number;
    width: number;
    height: number;
    rate: number;
    videoFile?: IVideoFile;
    audioOverlays: IAudioOverlay[];
}

export interface IAct {
    name: string;
    id: string;
    inventory: IVariables;
    scenes: IScenes;
}

export interface IImageScene {
    id: string;
    sceneData: IImageSceneData;
    type: string;
}

export interface IImageSceneData {
    width: number;
    height: number;
    backgroundColor: string;
    overlays: IOverlay[];
    imageOutputSettings: IImageStoryEncodingSetting[];
}

export interface IVideoScene {
    id: string;
    sceneDataFilter: string;
    sceneData: IVideoSceneData;
    type: string;
    exportAsPlaylist: boolean;
    postProcessingScript: string;
}

export interface IVideoSceneData {
    cuts: ICut[];
    videoFile: IVideoFile;
    encodingSettings: IVideoEncodingSetting[];
    audioOverlays: IAudioOverlay[];
    limitProcessConcurrency: boolean;
    processConcurrency: number;
    imageOutputSettings: IVideoStoryImageOutputSetting[];
}

export interface ICompositionScene {
    id: string;
    sceneDataFilter: string;
    sceneData: ICompositionSceneData;
    type: string;
    exportAsPlaylist: boolean;
    postProcessingScript: string;
}

export interface ICompositionSceneData {
    encodingSettings: IVideoEncodingSetting[];
    limitProcessConcurrency: boolean;
    processConcurrency: number;
    imageOutputSettings: IVideoStoryImageOutputSetting[];
    audioOutputSettings: IVideoStoryAudioOutputSetting[];
}

export interface IVideoFile {
    name: string;
    width: number;
    height: number;
    type: string;
    duration: number;
    rate: number;
    totalFrames: number;
    url: string;
    frames: string;
    filesize?: number;
    keyframes?: string;
}

export interface IVideoEncodingSetting {
    name: string;
    video: string;
    audio: string;
    width: number;
    height: number;
    extension: string;
    resize: string;
}

export interface IVideoStoryImageOutputSetting {
    name: string;
    width: number;
    height: number;
    quality: number;
    extension: string;
    resize: string;
    cut_id: string;
    frame: number;
    poster: boolean;
    script: string;
}

export interface IVideoStoryAudioOutputSetting {
    name: string;
    bitrate: number;
    sample_rate: number;
    channels: number;
    extension: string;
}

export interface IAudioOverlay {
    id: string;
    offset: number;
    volume: number;
    name: string;
    type: string;
    source: any;
}

export interface IImageStoryEncodingSetting {
    quality: number;
    name: string;
    width: number;
    height: number;
    extension: string;
    resize: string;
}

export interface IAssetList {
    loading: boolean;
    page: number;
    total_pages: number;
    asset_count: number;
    assets: any[];
}

export interface IAPIScene {
    id: string;
    name?: string;
    sceneData: IAPISceneData;
    type: string;
}

export interface IAPISceneData {
    isIntegration?: boolean;
    url: string;
    requestType: string;
    setupScript: string;
    videoItem: string;
    headers: IKeyValuePair[];
    additionalParams: IKeyValuePair[];
}

export interface IKeyValuePair {
    key: string;
    value: string;
}

export const NEW_KEY_VALUE_PAIR: IKeyValuePair = {
    key: '',
    value: ''
};

export const NEW_API_SCENE: IAPIScene = {
    id: '',
    type: SCENE_TYPES.API,
    sceneData: null
};

export const NEW_API_SCENE_DATA: IAPISceneData = {
    isIntegration: false,
    url: '',
    requestType: API_REQUEST_TYPES.POST,
    setupScript: '',
    videoItem: '',
    headers: [],
    additionalParams: []
};

export const NEW_ENCODING_SETTING: IVideoEncodingSetting = {
    name: '',
    video: `${
        ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.MP4]
    } -b:v 1800k -maxrate:v 2700k -bufsize:v 3600k`,
    audio: `${ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.MP4]} -b:a 192k`,
    width: null,
    height: null,
    extension: VIDEO_FORMATS.MP4,
    resize: 'crop'
};

export const NEW_IMAGE_ENCODING_SETTING: IImageStoryEncodingSetting = {
    name: '',
    width: 0,
    height: 0,
    quality: 3,
    extension: 'jpg',
    resize: 'crop'
};

export const NEW_VIDEO_IMAGE_ENCODING_SETTING: IVideoStoryImageOutputSetting = {
    name: '',
    width: 0,
    height: 0,
    quality: 3,
    extension: 'jpg',
    resize: 'crop',
    cut_id: '',
    frame: 0,
    poster: false,
    script: null
};

export const NEW_VIDEO_AUDIO_ENCODING_SETTING: IVideoStoryAudioOutputSetting = {
    name: '',
    bitrate: AUDIO_BITRATES[AUDIO_FORMATS.MP3][3],
    sample_rate: AUDIO_SAMPLE_RATES[5],
    channels: AUDIO_CHANNEL_OPTIONS[1].value,
    extension: AUDIO_FORMATS.MP3
};

export interface ICut {
    id: string;
    name: string;
    clipSwap: boolean;
    useCutLength: boolean;
    startFrame: number;
    endFrame: number;
    previewURL: string;
    renderTime: number;
    previousRenderTime: number;
    staticOverlayMethod: string;
    tags: ITag[];
    overlays: IOverlay[];
    audioSource?: string;
    offset?: number;
    effects?: ICutEffects;
    assetId?: string;
}

export const NEW_CUT: ICut = {
    id: '',
    name: 'New Cut',
    clipSwap: false,
    useCutLength: false,
    startFrame: null,
    endFrame: null,
    previewURL: '',
    renderTime: null,
    previousRenderTime: null,
    staticOverlayMethod: 'auto',
    audioSource: AUDIO_SOURCES.CLIP,
    tags: [],
    overlays: [],
    effects: {},
    assetId: null
};

export interface IOverlay {
    id: string;
    name: string;
    type: string;
    enabled: boolean;
    position: string;
    position_inputs: any;
    width: number;
    height: number;
    phantom_version: number;
    engine: string;
    source: any;
    generating_script?: string;
    effects: IOverlayEffects;
    background_color: string;
}

export interface IOverlayEffects {
    blend_mode?: string;
    mask_asset_id?: string;
    opacity?: number;
    saturation?: number;
    hue?: number;
    brightness?: number;
    motion_blur?: number;
    lut_asset_id?: string;
}

export interface ICutEffects {
    saturation?: number;
    hue?: number;
    brightness?: number;
    lut_asset_id?: string;
}

export interface ITag {
    required: boolean;
    value: string;
    type: string;
}

export interface IAssetSource {
    from: string;
    asset_type: string;
    asset_vars: ICustomVar[];
    asset_id: string;
}

export interface IAssetSolidSource {
    from: string;
    color: string;
}

export interface IAssetTagsSource {
    from: string;
    asset_type: string;
    asset_vars: ICustomVar[];
    asset_tags: ITag[];
    optional: boolean;
}

export interface ITextSource {
    from: string;
    text: string;
    text_to_speech: ITTSConfig;
}

export interface IInventorySource {
    from: string;
    version: string;
    inventory_id: string;
}

export interface ICustomVar {
    var_type: string;
    var_source: string;
    key: string;
    value: string;
}

export interface IVariableDefault {
    type: string;
    src: any;
    options?: any;
}

export interface IVariable {
    name: string;
    type: string;
    optional: boolean;
    hidden: boolean;
    id: string;
    integration?: string;
    defaultItem: IVariableDefault;
    previewItem: IVariableDefault;
    maxDuration?: number;
    versions?: any[];
}

export interface IVariables {
    [s: string]: IVariable;
}

export interface IVideoVariableVersion {
    width: number;
    height: number;
    name: string;
    resize: string;
    id: string;
}

export const NEW_VIDEO_VARIABLE_VERSION: IVideoVariableVersion = {
    width: 1280,
    height: 720,
    name: 'New Version',
    resize: 'crop',
    id: ''
};

export interface IScenes {
    [s: string]: IVideoScene;
}

export interface IMTQuadPosition {
    coords: string;
}

export interface IMTRectPosition {
    coords: string;
    width: number;
    height: number;
}

export interface IRect {
    width: number;
    height: number;
    x: number;
    y: number;
    anchorX: number;
    anchorY: number;
    widthUnit: string;
    heightUnit: string;
    xUnit: string;
    yUnit: string;
    anchorXUnit: string;
    anchorYUnit: string;
    scaleX: number;
    scaleY: number;
    aspect_mode: string;
    vertical_align: string;
    horizontal_align: string;
    originX: string;
    originY: string;
}

interface ICoord {
    x: number;
    y: number;
}

export interface IQuad {
    top_left: ICoord;
    top_right: ICoord;
    bottom_left: ICoord;
    bottom_right: ICoord;
}

export interface IAnimation {
    type: string;
    duration: number;
    delay: number;
    ease: string;
}

export const NEW_PROJECT: IStory = {
    id: '',
    name: '',
    enabled: true,
    metadata: { ...NEW_METADATA },
    moderationType: 'post',
    moderationApprovedScene: '',
    moderationRejectedScene: '',
    moderationAwaitApproval: false,
    acts: {},
    discardUgc: false,
    gaTrackingId: '',
    whitelistedUrls: '*',
    disabled: false,
    variableAssets: true,
    viewer: null,
    queueId: ''
};

export const NEW_ACT: IAct = {
    id: '',
    name: 'Act',
    inventory: {},
    scenes: {}
};

export const NEW_OVERLAY: IOverlay = {
    id: '',
    name: 'New Overlay',
    type: OVERLAY_TYPES.IMAGE,
    enabled: true,
    position: '',
    position_inputs: null,
    width: null,
    height: null,
    phantom_version: 2,
    engine: OVERLAY_ENGINE_TYPES.CHROMIUM,
    background_color: null,
    source: null,
    effects: {}
};

export const NEW_AUDIO_OVERLAY: IAudioOverlay = {
    name: 'New Audio Overlay',
    id: '',
    volume: 1,
    offset: 0,
    type: OVERLAY_TYPES.AUDIO,
    source: null
};

export const NEW_VARIABLE: IVariable = {
    name: '',
    id: '',
    type: '',
    optional: true,
    hidden: false,
    defaultItem: null,
    previewItem: null
};

export const NEW_VARIABLE_DEFAULT: IVariableDefault = {
    type: null,
    src: null,
    options: null
};

export const NEW_IMAGE_SCENE: IImageScene = {
    id: '',
    type: SCENE_TYPES.IMAGE,
    sceneData: {
        width: null,
        height: null,
        overlays: [],
        backgroundColor: '#ffffff',
        imageOutputSettings: []
    }
};

export const NEW_VIDEO_SCENE: IVideoScene = {
    id: '',
    sceneDataFilter: '',
    sceneData: {
        cuts: [],
        videoFile: null,
        encodingSettings: [{ ...ENCODING_SETTING_PRESETS['MP4 H.264 1920x1080'] }],
        audioOverlays: [],
        imageOutputSettings: [],
        limitProcessConcurrency: true,
        processConcurrency: 20
    },
    type: SCENE_TYPES.VIDEO,
    exportAsPlaylist: false,
    postProcessingScript: ''
};

export const NEW_COMPOSITION_SCENE: ICompositionScene = {
    id: '',
    sceneDataFilter: '',
    sceneData: {
        encodingSettings: [],
        imageOutputSettings: [],
        audioOutputSettings: [],
        limitProcessConcurrency: true,
        processConcurrency: 20
    },
    type: SCENE_TYPES.COMPOSITION,
    exportAsPlaylist: false,
    postProcessingScript: ''
};

export const NEW_COMPOSITION: ILayeredComposition = {
    layers: [],
    background_color: '#000000',
    frames: 120,
    width: 1920,
    height: 1080,
    rate: 24,
    fit_layer_duration: false,
    excluded_video_outputs: [],
    excluded_image_outputs: [],
    excluded_audio_outputs: []
};

export const NEW_ASSET_SOURCE: IAssetSource = {
    from: SOURCE_TYPES.ASSET,
    asset_type: '',
    asset_vars: [],
    asset_id: ''
};

export const NEW_ASSET_SOLID_SOURCE: IAssetSolidSource = {
    from: SOURCE_TYPES.COLOR,
    color: '#ff0000ff'
};

export const NEW_ASSET_TAGS_SOURCE: IAssetTagsSource = {
    from: SOURCE_TYPES.ASSET_TAGS,
    asset_type: '',
    asset_vars: [],
    asset_tags: [],
    optional: false
};

export const NEW_INVENTORY_SOURCE: IInventorySource = {
    from: SOURCE_TYPES.INVENTORY,
    version: '',
    inventory_id: ''
};

export const NEW_CUSTOM_VAR: ICustomVar = {
    var_type: 'text',
    var_source: 'text',
    key: '',
    value: ''
};

export const NEW_MT_QUAD_POSITION: IMTQuadPosition = {
    coords: ''
};

export const NEW_MT_RECT_POSITION: IMTRectPosition = {
    coords: '',
    width: null,
    height: null
};

export const NEW_RECT_POSITION: IRect = {
    x: null,
    y: null,
    xUnit: LAYOUT_UNITS.PIXELS,
    yUnit: LAYOUT_UNITS.PIXELS,
    width: null,
    height: null,
    widthUnit: LAYOUT_UNITS.PIXELS,
    heightUnit: LAYOUT_UNITS.PIXELS,
    anchorX: 0,
    anchorY: 0,
    anchorXUnit: LAYOUT_UNITS.PIXELS,
    anchorYUnit: LAYOUT_UNITS.PIXELS,
    scaleX: 1,
    scaleY: 1,
    originX: ORIGINS.LEFT,
    originY: ORIGINS.TOP,
    aspect_mode: RESIZE_TYPES.STRETCH,
    vertical_align: 'center',
    horizontal_align: 'center'
};

export const NEW_QUAD_POSITION: IQuad = {
    top_left: {
        x: null,
        y: null
    },
    top_right: {
        x: null,
        y: null
    },
    bottom_left: {
        x: null,
        y: null
    },
    bottom_right: {
        x: null,
        y: null
    }
};

export const NEW_ANIMATION: IAnimation = {
    type: '',
    duration: 1,
    delay: 0,
    ease: 'linear'
};

export interface ILayeredComposition {
    background_color: string;
    frames: number;
    width: number;
    height: number;
    rate: number;
    layers: ICompositionLayer[];
    id?: string;
    name?: string;
    fit_layer_duration?: boolean;
    excluded_video_outputs?: string[];
    excluded_image_outputs?: string[];
    excluded_audio_outputs?: string[];
}

export interface IKeyframe {
    id: string;
    type: string;
    relativeFrame: number;
    value: any;
    ease?: string;
}

export interface ICompositionLayerKeyframes {
    position?: IKeyframe[];
    volume?: IKeyframe[];
    size?: IKeyframe[];
    anchor?: IKeyframe[];
    scale?: IKeyframe[];
    opacity?: IKeyframe[];
}

export interface ICompositionLayer {
    id: string;
    name: string;
    offset_frames: number;
    start_frame: number;
    end_frame: number;
    keyframes: ICompositionLayerKeyframes;
    keyframe_interpolation: string;
    effects: ICompositionLayerEffects;
    type: string;
    options: any;
    position: string;
    position_inputs: any;
    video_enabled: boolean;
    audio_enabled: boolean;
    locked: boolean;
    anchor_start?: ILayerAnchor;
    anchor_end?: ILayerAnchor;
    matte_layer_id?: string;
    renderPos?: any;
}

export const NEW_COMPOSITION_LAYER: ICompositionLayer = {
    id: '',
    name: '',
    offset_frames: 0,
    start_frame: null,
    end_frame: null,
    effects: {},
    type: '',
    options: null,
    position: null,
    keyframes: null,
    keyframe_interpolation: LAYER_KEYFRAME_INTERPOLATION_TYPES.STATIC,
    position_inputs: null,
    video_enabled: true,
    audio_enabled: true,
    matte_layer_id: null,
    locked: false
};

export interface ICompositionTextLayerOptions {
    content: string;
    color: string;
    background_color: string;
    font_size: number;
    font_type: string;
    font: string;
    font_weight: string | number;
    custom_font_id: string;
    custom_font_url: string;
    letter_spacing: number;
    word_spacing: number;
    line_height: number;
    horizontal_alignment: string;
    vertical_alignment: string;
    text_wrap: boolean;
    text_fit: boolean;
    animation_type: string;
    highlight_color: string;
    stroke_color: string;
    stroke_weight: number;
    upper_case: boolean;
    font_style: string;
}

export const NEW_TEXT_LAYER_OPTIONS: ICompositionTextLayerOptions = {
    content: '',
    color: '#ffffff',
    highlight_color: '#E0DA18',
    stroke_color: 'transparent',
    stroke_weight: null,
    background_color: 'transparent',
    font_size: 100,
    font_type: 'standard',
    font: DEFAULT_FONT,
    font_weight: DEFAULT_FONT_WEIGHT,
    custom_font_id: null,
    custom_font_url: null,
    letter_spacing: null,
    word_spacing: null,
    line_height: null,
    horizontal_alignment: 'center',
    vertical_alignment: 'center',
    text_wrap: true,
    text_fit: true,
    animation_type: '',
    upper_case: false,
    font_style: 'normal'
};

export interface ICompositionMediaLayerOptions {
    source: any;
    mute_if_below: boolean;
    persist_asset: boolean;
    use_swap_duration: boolean;
}

export const NEW_MEDIA_LAYER_OPTIONS: ICompositionMediaLayerOptions = {
    source: null,
    mute_if_below: false,
    use_swap_duration: false,
    persist_asset: true
};

export interface ICompositionTemplateLayerOptions {
    width: number;
    height: number;
    background_color: string;
    source: any;
    animated: boolean;
}

export interface ICompositionSolidLayerOptions {
    width: number;
    height: number;
}

export const NEW_TEMPLATE_LAYER_OPTIONS: ICompositionTemplateLayerOptions = {
    width: null,
    height: null,
    background_color: null,
    source: null,
    animated: true
};

export const NEW_HTML_LAYER_OPTIONS: ICompositionTemplateLayerOptions = {
    width: null,
    height: null,
    background_color: null,
    source: null,
    animated: true
};

export const NEW_SOLID_LAYER_OPTIONS: ICompositionSolidLayerOptions = {
    width: null,
    height: null
};

export interface ICompositionLayerEffects {
    blend_mode?: string;
    mask_asset_id?: string;
    opacity?: number;
    saturation?: number;
    hue?: number;
    brightness?: number;
    motion_blur?: number;
    lut_asset_id?: string;
    volume?: string;
}

export interface ILayerAnchor {
    attachment_point: string;
    id: string;
}

export const NEW_ANCHOR = {
    attachment_point: 'end_frame',
    id: ''
};

export const colorPresets = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    '#FFFFFF',
    'transparent'
];

export interface IPreviewScale {
    width: number;
    height: number;
    top: number;
    left: number;
    scale: number;
}

export interface ITTSConfig {
    service: string;
    voice: string;
}

export const NEW_TTS_CONFIG: ITTSConfig = {
    service: TTS_SERVICES.ELEVENLABS,
    voice: TTS_SERVICE_DEFAULTS[TTS_SERVICES.ELEVENLABS]
};

export const NEW_TEXT_SOURCE: ITextSource = {
    from: SOURCE_TYPES.TEXT,
    text: '',
    text_to_speech: NEW_TTS_CONFIG
};
