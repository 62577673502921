import actions from '../actions/layerCopy';

const initialState: any = {};

const layerCopy = (state = initialState, action): any => {
    switch (action.type) {
        case actions.COPY_LAYER:
            return {
                ...state,
                copied: action.layer
            };
        case actions.CLEAR_COPY:
            return {};
        default:
            return state;
    }
};

export default layerCopy;
