import actions from '../actions/queues';

const initialState = [];

const queues = (state = initialState, action) => {
    switch (action.type) {
        case actions.RECEIVE:
            return [...action.queues];
        default:
            return state;
    }
};

export default queues;
