import actions from '../actions/layerErrors';

const initialState: any = {};

const layerErrors = (state = initialState, action): any => {
    const { id, errors } = action;
    switch (action.type) {
        case actions.SET_ERRORS:
            if (!state[id]) {
                return {
                    ...state,
                    ...{ [action.id]: errors }
                };
            } else {
                return {
                    ...state,
                    ...{ [action.id]: { ...state[id], ...errors } }
                };
            }
        default:
            return state;
    }
};

export default layerErrors;
