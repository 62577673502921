import actions from '../actions/timeline';

const initialState: any = {
    layerDragOffset: 0,
    rightDragFrameOffset: 0,
    leftDragFrameOffset: 0,
    tempOffsetFrames: null,
    activeFrame: {},
    scaleModifier: {},
    positionOffset: {},
    activeTimelineCut: null,
    activeTimelineOverlay: null,
    activeTimelineLayer: null,
    activeKeyframe: null,
    activeMultiSelectLayers: [],
    expandedLayers: [],
    playing: false,
    muted: false,
    volume: 1
};

export interface ITimelineState {
    layerDragOffset: number;
    rightDragFrameOffset: number;
    leftDragFrameOffset: number;
    tempOffsetFrames: number;
    activeFrame: object;
    scaleModifier: object;
    positionOffset: object;
    activeTimelineCut: string;
    activeTimelineOverlay: string;
    activeTimelineLayer: string;
    activeKeyframe: string;
    activeMultiSelectLayers: string[];
    expandedLayers: string[];
    playing: boolean;
    muted: boolean;
    volume: number;
}

const timeline = (state = initialState, action): any => {
    let newState: ITimelineState;
    switch (action.type) {
        case actions.UPDATE:
            newState = { ...state, ...action.config };
            return newState;
        default:
            return state;
    }
};

export default timeline;
