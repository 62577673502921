import actions from '../actions/notifications';

export interface INotification {
    time: string;
    text: string;
    type: string;
    button?: string;
}

const initialState: INotification[] = [];

const notifications = (state = initialState, action): any => {
    switch (action.type) {
        case actions.LOG:
            return [action.notification, ...state];
        case actions.CLEAR:
            return [];
        default:
            return state;
    }
};

export default notifications;
