import { FRAME_WIDTH, LAYER_EXPANDED_ROW_HEIGHT, LAYER_ROW_HEIGHT } from '../constants/timeline';
import moize from 'moize';
import { EASING_FUNCTIONS } from './easing';
import { ASSET_TYPES, EASE_TYPES, LAYOUT_UNITS, ORIGINS } from '../constants/story';
import { generateUUID } from './story';
import { arrayMove, trimToFourDecimalPlaces } from './general';
import store from '../redux/store';
import { logError } from './notifications';
import { updateLayer } from '../redux/actions/compositions';

export const getFrameFromXPos = (x, scale): number => {
    const realFrameWidth = FRAME_WIDTH * scale;
    return Math.ceil(x / realFrameWidth);
};

export const getXPosFromFrame = (frame, scale): number => {
    return FRAME_WIDTH * frame * scale;
};

export const mGetRowY = (layers, expandedLayers, layerId): number => {
    let h = 0;
    for (let i = layers.length - 1; i >= 0; i--) {
        const atLayer = layers[i];
        if (atLayer) {
            if (atLayer.id === layerId) {
                break;
            }

            const layerHeight =
                expandedLayers.indexOf(atLayer.id) !== -1
                    ? LAYER_EXPANDED_ROW_HEIGHT
                    : LAYER_ROW_HEIGHT;

            h += layerHeight;
        }
    }

    return h;
};

export const mGetInterpolatedConfigValue = (keyframes, relativeFrame, keys?) => {
    const first = keyframes[0];
    const last = keyframes[keyframes.length - 1];
    const isCompound = keys && keys.length >= 0;

    // If the first keyframe is after this relative frame, return the first keyframe value
    if (first.relativeFrame >= relativeFrame) {
        return first.value;

        // If the last keyframe is before this relative frame, return the last keyframe value
    } else if (last.relativeFrame <= relativeFrame) {
        return last.value;

        // If neither of those cases are true, we must be between two keyframes, so interpolate between them
    } else {
        // Get the keyframes before and after this relative frame
        let fromKeyframe;
        let toKeyframe;
        for (const keyframe of keyframes) {
            if (keyframe.relativeFrame === relativeFrame) {
                return keyframe.value;
            } else if (keyframe.relativeFrame < relativeFrame) {
                if (
                    (fromKeyframe && keyframe.relativeFrame > fromKeyframe.relativeFrame) ||
                    !fromKeyframe
                ) {
                    fromKeyframe = keyframe;
                }
            } else if (keyframe.relativeFrame > relativeFrame && !toKeyframe) {
                toKeyframe = keyframe;
            }
            if (fromKeyframe && toKeyframe) {
                break;
            }
        }

        // If the keyframes are the same value, return that value
        if (fromKeyframe.value === toKeyframe.value) {
            return fromKeyframe.value;
        }

        // If they aren't the same value, interpolate between the two values and get the proper value
        const duration = toKeyframe.relativeFrame - fromKeyframe.relativeFrame;
        const progress = relativeFrame - fromKeyframe.relativeFrame;
        const ease = fromKeyframe.ease || EASE_TYPES.LINEAR;
        const scalar = EASING_FUNCTIONS[ease](progress, 0, 1, duration);

        // If this is a compound keyframe, interpolate each value in the object
        // If not, just interpolate the number
        if (isCompound) {
            const returnObj: any = {};
            for (const key of keys) {
                const vector = toKeyframe.value[key] - fromKeyframe.value[key];
                returnObj[key] = trimToFourDecimalPlaces(fromKeyframe.value[key] + vector * scalar);
            }
            return returnObj;
        } else {
            const vector = toKeyframe.value - fromKeyframe.value;
            return trimToFourDecimalPlaces(fromKeyframe.value + vector * scalar);
        }
    }
};

export const changeKeyframeValue = (keyframes, field, index, value) => {
    const newKeyframesObject = { ...keyframes };
    const existing = newKeyframesObject[field] || [];
    const newKeyframes = [...existing];
    const newKeyframe = { ...newKeyframes[index] };
    newKeyframe.value = value;
    newKeyframes[index] = newKeyframe;
    newKeyframesObject[field] = newKeyframes;

    return newKeyframesObject;
};

export const replaceKeyframe = (keyframes, field, keyframe) => {
    const index = keyframes[field].findIndex((k) => {
        return k.id === keyframe.id;
    });

    const newKeyframes = { ...keyframes };
    const newArray = [...newKeyframes[field]];
    newArray[index] = keyframe;
    newKeyframes[field] = newArray;

    return newKeyframes;
};

export const removeKeyframe = (keyframes, field, index) => {
    const newKeyframes = { ...keyframes };
    const newArray = [...newKeyframes[field]];
    newArray.splice(index, 1);
    newKeyframes[field] = newArray;
    return newKeyframes;
};

export const clearKeyframes = (keyframes, field) => {
    const newKeyframes = { ...keyframes };
    newKeyframes[field] = [];
    return newKeyframes;
};

export const addKeyframe = (keyframes, relativeFrame, field, keyframe) => {
    const newKeyframe = { ...keyframe };
    newKeyframe.id = generateUUID();
    newKeyframe.ease = EASE_TYPES.LINEAR;
    newKeyframe.relativeFrame = relativeFrame;
    const newKeyframesObject = { ...keyframes };
    const existing = newKeyframesObject[field] || [];
    let newKeyframes = [...existing];

    newKeyframes.push(newKeyframe);

    const currentIndex = newKeyframes.length - 1;
    let properIndex = 0;
    for (let i = 0; i < newKeyframes.length; i++) {
        const k = newKeyframes[i];
        if (relativeFrame > k.relativeFrame) {
            properIndex = i + 1;
        }
    }

    newKeyframes = arrayMove(newKeyframes, currentIndex, properIndex);
    newKeyframesObject[field] = newKeyframes;

    return newKeyframesObject;
};

export const toggleKeyframeUnit = (newUnit, keyframes, field, subField, scalar) => {
    const newKeyframesObject = { ...keyframes };
    const existing = newKeyframesObject[field] || [];
    const newKeyframes = [...existing];

    // Loop through all of the keyframes, and apply the scalar to the proper sub-value
    for (let k = 0; k < newKeyframes.length; k++) {
        const newKeyframe = { ...newKeyframes[k] };
        const newValObject = { ...newKeyframe.value };
        const newValue =
            newUnit === LAYOUT_UNITS.PERCENT
                ? newValObject[subField] / scalar
                : newValObject[subField] * scalar;
        newValObject[subField] = newValue;
        newKeyframe.value = newValObject;
        newKeyframes[k] = newKeyframe;
    }
    newKeyframesObject[field] = newKeyframes;
    return newKeyframesObject;
};

export const moveKeyframe = (keyframes, field, index, frames) => {
    const newKeyframeArray = { ...keyframes };
    const keyframeArray = [...keyframes[field]];
    const newRelativeFrame = keyframeArray[index].relativeFrame + frames;

    // Figure out where in the array this new frame should go, and if there's already a frame there
    let newIndex = 0;
    for (let i = 0; i < keyframeArray.length; i++) {
        const keyframe = keyframeArray[i];
        if (keyframe.relativeFrame === newRelativeFrame) {
            return;
        }
        if (keyframe.relativeFrame < newRelativeFrame) {
            if (frames < 0) {
                newIndex = i + 1;
            } else {
                newIndex = i;
            }
        }
    }

    // Update the keyframe relative frame number
    const newKeyframe = { ...keyframeArray[index] };
    newKeyframe.relativeFrame = newRelativeFrame;
    keyframeArray[index] = newKeyframe;

    // Move the keyframe to the correct position
    const updatedKeyframes = arrayMove(keyframeArray, index, newIndex);
    newKeyframeArray[field] = updatedKeyframes;

    return newKeyframeArray;
};

export const getKeyframeFieldAndIndex = (keyframes, keyframeId) => {
    for (const key in keyframes) {
        if (keyframes.hasOwnProperty(key)) {
            const keyframeArray = keyframes[key];
            for (let i = 0; i < keyframeArray.length; i++) {
                const keyframe = keyframeArray[i];
                if (keyframe.id === keyframeId) {
                    return {
                        field: key,
                        index: i
                    };
                }
            }
        }
    }

    return {
        field: null,
        index: null
    };
};

export const getPosValues = (
    positionInputs,
    keyframes,
    keyframeKey,
    relativeFrame,
    keys,
    defaults
): any => {
    const returnObj = {};
    // If the keyframes are set, get the position values there, if not, use the positionInputs
    if (keyframes && keyframes[keyframeKey] && keyframes[keyframeKey].length > 0) {
        const interpolatedValue = getInterpolatedConfigValue(
            keyframes[keyframeKey],
            relativeFrame,
            keys
        );
        returnObj[keys[0]] = interpolatedValue[keys[0]];
        returnObj[keys[1]] = interpolatedValue[keys[1]];
    } else {
        const val0 =
            positionInputs[keys[0]] !== undefined ? positionInputs[keys[0]] : [defaults[0]];
        const val1 =
            positionInputs[keys[1]] !== undefined ? positionInputs[keys[1]] : [defaults[1]];
        returnObj[keys[0]] = val0;
        returnObj[keys[1]] = val1;
    }
    return returnObj;
};

export const getLayerPosition = (
    positionInputs: any,
    keyframes: any,
    relativeFrame: number,
    compWidth: number,
    compHeight: number,
    applyScale: boolean = true,
    applyAnchorOffset: boolean = true
) => {
    let { x, y } = getPosValues(
        positionInputs,
        keyframes,
        'position',
        relativeFrame,
        ['x', 'y'],
        [0, 0]
    );

    let { width, height } = getPosValues(
        positionInputs,
        keyframes,
        'size',
        relativeFrame,
        ['width', 'height'],
        [1, 1]
    );

    let { anchorX, anchorY } = getPosValues(
        positionInputs,
        keyframes,
        'anchor',
        relativeFrame,
        ['anchorX', 'anchorY'],
        [0, 0]
    );

    const { scaleX, scaleY } = getPosValues(
        positionInputs,
        keyframes,
        'scale',
        relativeFrame,
        ['scaleX', 'scaleY'],
        [1, 1]
    );

    const xUnit = positionInputs.xUnit || LAYOUT_UNITS.PIXELS;
    const yUnit = positionInputs.yUnit || LAYOUT_UNITS.PIXELS;
    const widthUnit = positionInputs.widthUnit || LAYOUT_UNITS.PIXELS;
    const heightUnit = positionInputs.heightUnit || LAYOUT_UNITS.PIXELS;
    const anchorXUnit = positionInputs.anchorXUnit || LAYOUT_UNITS.PIXELS;
    const anchorYUnit = positionInputs.anchorYUnit || LAYOUT_UNITS.PIXELS;
    const originX = positionInputs.originX || ORIGINS.LEFT;
    const originY = positionInputs.originY || ORIGINS.TOP;

    // Apply the % transform to x / y if needed
    x = xUnit === LAYOUT_UNITS.PERCENT ? compWidth * x : x;
    y = yUnit === LAYOUT_UNITS.PERCENT ? compHeight * y : y;

    // Apply the origin transform to x / y if needed
    x = originX === ORIGINS.RIGHT ? compWidth - x : x;
    y = originY === ORIGINS.BOTTOM ? compHeight - y : y;

    // Apply the % transform to width / height if needed
    width = widthUnit === LAYOUT_UNITS.PERCENT ? compWidth * width : width;
    height = heightUnit === LAYOUT_UNITS.PERCENT ? compHeight * height : height;

    // Apply the % transform to the anchor x /y
    anchorX = anchorXUnit === LAYOUT_UNITS.PERCENT ? anchorX * width : anchorX;
    anchorY = anchorYUnit === LAYOUT_UNITS.PERCENT ? anchorY * height : anchorY;

    // Apply the scale to the width, heught, and anchor
    let scaledAnchorX;
    let scaledAnchorY;
    let scaledWidth;
    let scaledHeight;
    if (applyScale) {
        scaledAnchorX = anchorX * scaleX;
        scaledAnchorY = anchorY * scaleY;
        scaledWidth = width * scaleX;
        scaledHeight = height * scaleY;
    } else {
        scaledAnchorX = anchorX;
        scaledAnchorY = anchorY;
        scaledWidth = width;
        scaledHeight = height;
    }

    // modify the x/y using the anchor
    if (applyAnchorOffset) {
        x = x - scaledAnchorX;
        y = y - scaledAnchorY;
    }

    const pos = {
        aspect_mode: positionInputs.aspect_mode,
        vertical_align: positionInputs.vertical_align,
        horizontal_align: positionInputs.horizontal_align,
        x,
        y,
        width: scaledWidth,
        height: scaledHeight,
        anchorX: scaledAnchorX,
        anchorY: scaledAnchorY,
        originX,
        originY,
        xUnit,
        yUnit,
        widthUnit,
        heightUnit,
        anchorXUnit,
        anchorYUnit,
        scaleX,
        scaleY
    };

    return pos;
};

export const getInterpolatedConfigValue = moize(mGetInterpolatedConfigValue);

export const getRowY = moize(mGetRowY);

const memoGetAnchorsAnchoredTo = (layerId, layers, start) => {
    const anchoredTo = [];
    for (const layer of layers) {
        const { anchor_start, anchor_end } = layer;
        const attachmentTarget = start ? 'start_frame' : 'end_frame';
        if (
            anchor_start &&
            anchor_start.attachment_point === attachmentTarget &&
            anchor_start.id === layerId
        ) {
            anchoredTo.push(layer.id);
        }
        if (
            anchor_end &&
            anchor_end.attachment_point === attachmentTarget &&
            anchor_end.id === layerId
        ) {
            anchoredTo.push(layer.id);
        }
    }
    return anchoredTo;
};

export const getAnchorsAnchoredTo = moize(memoGetAnchorsAnchoredTo, { maxSize: 1000 });

export const getTimelineHeight = (): number => {
    const state = store.getState();

    const {
        compositions: { present },
        timeline: { expandedLayers },
        project: { compositionId }
    } = state;

    const layers = present[compositionId].layers;
    const numLayers = layers.length;
    const numExpandedLayers = expandedLayers.length;

    return Math.max(
        LAYER_ROW_HEIGHT * (numLayers - numExpandedLayers) +
            LAYER_EXPANDED_ROW_HEIGHT * numExpandedLayers,
        125
    );
};

const circularCompChecker = (compData, compositionId, assets) => {
    for (const obj of compData.layers) {
        if (obj) {
            const { type } = obj;
            if (type === ASSET_TYPES.VIDEO_COMPOSITION) {
                if (obj.options?.source?.asset_id) {
                    const {
                        options: {
                            source: { asset_id }
                        }
                    } = obj;
                    if (asset_id === compositionId) {
                        return true;
                    } else {
                        const data = assets.find((x) => x.id === asset_id).data;
                        const layerData = JSON.parse(data);
                        const next = circularCompChecker(layerData, compositionId, assets);
                        if (next) return next;
                    }
                }
            }
        }
    }
};

export const circularCompCheck = (asset) => {
    const {
        project: { compositionId },
        assetList: { assets }
    } = store.getState();

    if (asset.type === ASSET_TYPES.VIDEO_COMPOSITION) {
        if (asset.id === compositionId) {
            logError('Circular Composition detected. Composition not added.');
            return true;
        }

        const data = assets.find((x) => x.id === asset.id)?.data;
        if (data) {
            const compData = JSON.parse(data);
            if (circularCompChecker(compData, compositionId, assets)) {
                logError('Circular Composition detected. Composition not added.');
                return true;
            }
        }
    }
};

export const updateLayerHandler = (config, layer) => {
    const {
        project: { compositionId }
    } = store.getState();

    const merged = { ...config, ...layer };
    store.dispatch(updateLayer(compositionId, merged));
};
