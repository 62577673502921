import { getFirstAct, getFirstScene } from '../../util/story';
import { ASSET_TYPES, SCENE_TYPES } from '../../constants/story';
import { localStorageLoad, localStorageSave } from '../../util/general';

export interface IProject {
    storyId: string;
    actId: string;
    sceneId: string;
    compositionId: string;
}

const defaultState: IProject = {
    storyId: '',
    actId: '',
    sceneId: '',
    compositionId: ''
};

const storageKey = 'imposium-project-state';
let stateFromStorage = localStorageLoad(storageKey);
if (stateFromStorage) {
    stateFromStorage = { ...stateFromStorage };
}

const initialState = stateFromStorage || defaultState;

// Same as story actions, but not imported to avoice circular deps
const actions: any = {
    SET_STORY: 'story/SET_STORY',
    DELETE_STORY: 'story/DELETE_STORY',
    ADD_VIEWER: 'viewer/ADD',
    REPLACE_VIEWER: 'viewer/REPLACE',
    CLOSE_VIEWER: 'viewer/CLOSE',
    SET_ACTIVE: 'viewer/SET_ACTIVE'
};

const project = (state = initialState, action): any => {
    let newState: IProject;

    switch (action.type) {
        case actions.ADD_VIEWER:
        case actions.REPLACE_VIEWER:
            newState = { ...state };
            if (action.viewConfig.type === ASSET_TYPES.VIDEO_COMPOSITION) {
                newState.compositionId = action.viewConfig.id;
            }
            return newState;

        case actions.SET_ACTIVE:
            newState = { ...state };

            const tab = action.tabs.find((t) => {
                if (t.id === action.id) {
                    return true;
                }
            });

            if (tab && tab.type === ASSET_TYPES.VIDEO_COMPOSITION) {
                newState.compositionId = tab.id;
            }

            return newState;

        case actions.CLOSE_VIEWER:
            newState = { ...state };
            if (action.id === newState.compositionId) {
                newState.compositionId = '';
            }
            return newState;

        case actions.SET_STORY:
            newState = { ...state };

            let foundComp = false;
            if (action.data) {
                newState.storyId = action.data.id;
                const act = getFirstAct(action.data);
                newState.actId = Object.keys(action.data.acts)[0];
                const scene = getFirstScene(act, [
                    SCENE_TYPES.VIDEO,
                    SCENE_TYPES.IMAGE,
                    SCENE_TYPES.COMPOSITION
                ]);
                newState.sceneId = scene.id;
                const viewer = action.data.viewer;

                if (viewer) {
                    for (const t of viewer.tabs) {
                        if (
                            t.type === ASSET_TYPES.VIDEO_COMPOSITION &&
                            t.id === viewer.activeViewer
                        ) {
                            newState.compositionId = t.id;
                            foundComp = true;
                        }
                    }
                }
            }

            if (!foundComp) {
                newState.compositionId = null;
            }

            localStorageSave(storageKey, newState);

            return newState;

        case actions.DELETE_STORY:
            newState = { ...state };
            newState.storyId = '';
            newState.actId = '';
            newState.sceneId = '';
            newState.compositionId = '';

            localStorageSave(storageKey, newState);

            return newState;

        default:
            return state;
    }
};

export default project;
