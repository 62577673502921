import { ASSET_TYPES } from '../constants/story';

export const localStorageLoad = (key): any => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return null;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return null;
    }
};

export const localStorageSave = (key, data): void => {
    try {
        const serializedState = JSON.stringify(data);
        localStorage.setItem(key, serializedState);
    } catch (e) {
        console.error('Failed to save to local storage:', e);
    }
};

export const arrayMove = (arr, old_index, new_index): any[] => {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export const capitalize = (word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
};

export const rgbToHex = (r, g, b) =>
    '#' +
    [r, g, b]
        .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        })
        .join('');

export const rgbaToHexa = (color) => {
    let r = color.r.toString(16);
    let g = color.g.toString(16);
    let b = color.b.toString(16);
    let a = Math.round(color.a * 255).toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;
    if (a.length === 1) a = '0' + a;
    return '#' + r + g + b + a;
};

export const degreesToRadians = (degrees) => {
    const pi = Math.PI;
    return degrees * (pi / 180);
};

export const s3Url = (cloudFrontUrl: string, distro, origin): string => {
    if (cloudFrontUrl) {
        return cloudFrontUrl.replace(distro, origin);
    } else {
        return '';
    }
};

export const getNotificationTime = () => {
    const date = new Date();
    let hr: number | string = date.getHours();
    let min: number | string = date.getMinutes();
    let sec: number | string = date.getSeconds();

    if (hr > 12) {
        hr = hr - 12;
    }

    if (hr < 10) {
        hr = `0${hr}`;
    }

    if (min < 10) {
        min = `0${min}`;
    }

    if (sec < 10) {
        sec = `0${sec}`;
    }

    return `${hr}:${min}:${sec}`;
};

export const trimToFourDecimalPlaces = (num): number => {
    return Math.round(num * 10000) / 10000;
};

export const getDevicePixelRatio = () => {
    const ctx = document.createElement('canvas').getContext('2d');
    const dpr = window.devicePixelRatio || 1;
    const bsr =
        ctx['webkitBackingStorePixelRatio'] ||
        ctx['mozBackingStorePixelRatio'] ||
        ctx['msBackingStorePixelRatio'] ||
        ctx['oBackingStorePixelRatio'] ||
        ctx['backingStorePixelRatio'] ||
        1;

    return dpr / bsr;
};

export const cartesian = (args) => {
    const r = [];
    const max = args.length - 1;
    const helper = (arr, i) => {
        for (let j = 0, l = args[i].length; j < l; j++) {
            const a = arr.slice(0); // clone arr
            a.push(args[i][j]);
            if (i === max) r.push(a);
            else helper(a, i + 1);
        }
    };
    helper([], 0);
    return r;
};

export const getInputFilter = (type: string, isAsset: boolean = false) => {
    switch (type) {
        case ASSET_TYPES.IMAGE:
        case ASSET_TYPES.VIDEO:
        case ASSET_TYPES.AUDIO:
        case ASSET_TYPES.FONT:
            return `${type}/*`;
        case ASSET_TYPES.IMAGE_SEQUENCE:
        case ASSET_TYPES.TEMPLATE:
            return 'zip,application/zip,application/x-zip,application/x-zip-compressed';
        case ASSET_TYPES.HTML:
            return '.json,application/json';
        default:
            return '';
    }
};

export const dropableAssetTypeCheck = (type: any) => {
    switch (type) {
        case ASSET_TYPES.VIDEO:
        case ASSET_TYPES.IMAGE:
        case ASSET_TYPES.IMAGE_SEQUENCE:
        case ASSET_TYPES.VIDEO_COMPOSITION:
        case ASSET_TYPES.AUDIO:
        case ASSET_TYPES.TEMPLATE:
        case ASSET_TYPES.HTML:
            return true;
        default:
            return false;
    }
};
