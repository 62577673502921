const errors = {
    CUT_ERROR: 'errors/CUT_ERROR',
    CLEAR_CUT_ERROR: 'errors/CLEAR_CUT_ERROR',
    OVERLAY_ERROR: 'errors/OVERLAY_ERROR',
    CLEAR_OVERLAY_ERROR: 'errors/CLEAR_OVERLAY_ERROR',
    AUDIO_ERROR: 'errors/AUDIO_ERROR',
    CLEAR_AUDIO_ERROR: 'errors/CLEAR_AUDIO_ERROR'
};

export const cutErrors = (c): any => {
    return (dispatch) => {
        dispatch({ type: errors.CUT_ERROR, c });
    };
};

export const clearCutErrors = () => {
    return (dispatch) => dispatch({ type: errors.CLEAR_CUT_ERROR });
};

export const overlayErrors = (e): any => {
    return (dispatch) => {
        dispatch({ type: errors.OVERLAY_ERROR, e });
    };
};

export const clearOverlayErrors = () => {
    return (dispatch) => dispatch({ type: errors.CLEAR_OVERLAY_ERROR });
};

export const overlayAudioErrors = (a): any => {
    return (dispatch) => {
        dispatch({ type: errors.AUDIO_ERROR, a });
    };
};

export const clearOverlayAudioErrors = (): any => {
    return (dispatch) => {
        dispatch({ type: errors.CLEAR_AUDIO_ERROR });
    };
};

export default errors;
