export const SCENE_TYPES = {
    VIDEO: 'VideoScene01',
    COMPOSITION: 'CompositionScene01',
    IMAGE: 'ImageScene01',
    AUDIO: 'AudioScene01',
    API: 'ApiScene01'
};

export const MODERATION_STATUS = {
    PRE: 'pre',
    POST: 'post',
    USED: 'bypass'
};

export const MODERATION_STATUS_OPTIONS = [
    {
        value: MODERATION_STATUS.PRE,
        label: 'Pending'
    },
    {
        value: MODERATION_STATUS.POST,
        label: 'Approved'
    },
    {
        value: MODERATION_STATUS.USED,
        label: 'Used'
    }
];

export const AUDIO_SOURCES = {
    BASE: 'base',
    CLIP: 'clip',
    MUTE: 'mute'
};

export const AUDIO_SOURCE_OPTIONS = [
    {
        value: AUDIO_SOURCES.CLIP,
        label: 'Clip'
    },
    {
        value: AUDIO_SOURCES.BASE,
        label: 'Base Video'
    },
    {
        value: AUDIO_SOURCES.MUTE,
        label: 'Mute'
    }
];

export const BOOLEAN_DEFAULT_OPTIONS = [
    {
        value: true,
        label: 'True'
    },
    {
        value: false,
        label: 'False'
    }
];

export const VARIABLE_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    ENUM: 'enum',
    IMAGE: 'image',
    VIDEO: 'video',
    AUDIO: 'audio'
};

export const VARIABLE_TYPE_OPTIONS = [
    {
        value: VARIABLE_TYPES.TEXT,
        label: 'Text'
    },
    {
        value: VARIABLE_TYPES.NUMBER,
        label: 'Number'
    },
    {
        value: VARIABLE_TYPES.BOOLEAN,
        label: 'Boolean'
    },
    {
        value: VARIABLE_TYPES.ENUM,
        label: 'List'
    },
    {
        value: VARIABLE_TYPES.IMAGE,
        label: 'Image'
    },
    {
        value: VARIABLE_TYPES.VIDEO,
        label: 'Video'
    },
    {
        value: VARIABLE_TYPES.AUDIO,
        label: 'Audio'
    }
];

export const RATE_OPTIONS = [
    {
        value: 23.976023976023978,
        label: 23.976
    },
    {
        value: 24,
        label: 24
    },
    {
        value: 25,
        label: 25
    },
    {
        value: 29.97002997,
        label: 29.97
    },
    {
        value: 30,
        label: 30
    },
    {
        value: 60,
        label: 60
    }
];

export const OVERLAY_METHOD_OPTIONS = [
    {
        value: 'auto',
        label: 'Auto'
    },
    {
        value: 'video',
        label: 'On Video'
    },
    {
        value: 'frames',
        label: 'On Frames'
    }
];

export const TAG_TYPES = {
    TEXT: 'text',
    VARIABLE: 'inventory'
};

export const TAG_TYPE_OPTIONS = [
    {
        value: TAG_TYPES.TEXT,
        label: 'Static'
    },
    {
        value: TAG_TYPES.VARIABLE,
        label: 'Variable'
    }
];

export const TAG_REQUIRED_OPTIONS = [
    {
        value: true,
        label: 'Required'
    },
    {
        value: false,
        label: 'Optional'
    }
];

export const DEFAULT_TAG_REQUIRED = true;

export const DEFAULT_TAG_TYPE = 'text';

export const OVERLAY_TYPES = {
    IMAGE: 'image',
    VIDEO: 'video',
    AUDIO: 'audio',
    SWAP: 'swap'
};

export const OVERLAY_TYPE_OPTIONS = [
    {
        value: OVERLAY_TYPES.IMAGE,
        label: 'Image'
    },
    {
        value: OVERLAY_TYPES.VIDEO,
        label: 'Video'
    }
];

export const ADVANCED_OVERLAY_TYPE_OPTIONS = [
    {
        value: OVERLAY_TYPES.IMAGE,
        label: 'Image'
    },
    {
        value: OVERLAY_TYPES.VIDEO,
        label: 'Video'
    },
    {
        value: OVERLAY_TYPES.SWAP,
        label: 'Swap'
    }
];

export const SOURCE_TYPES = {
    ASSET: 'asset_id',
    ASSET_TAGS: 'asset_tags',
    INVENTORY: 'inventory_id',
    SCRIPT: '',
    COLOR: 'color',
    TEXT: 'text'
};

export const ASSET_TYPES = {
    HTML: 'html',
    VIDEO: 'video',
    IMAGE: 'image',
    TEMPLATE: 'template',
    AUDIO: 'audio',
    IMAGE_SEQUENCE: 'image_sequence',
    FONT: 'font',
    VIDEO_COMPOSITION: 'video_composition',
    LUT: 'lut',
    ALL: 'all',
    AFTER_EFFECT: 'after_effects',
    UPLOAD: 'upload',
    SOLID: 'solid'
};

export const SOURCE_TYPE_OPTIONS = {
    [ASSET_TYPES.VIDEO]: [
        {
            value: SOURCE_TYPES.ASSET,
            label: 'Asset'
        },
        {
            value: SOURCE_TYPES.ASSET_TAGS,
            label: 'Asset Pool'
        },
        {
            value: SOURCE_TYPES.INVENTORY,
            label: 'Variable'
        }
    ],
    [ASSET_TYPES.IMAGE]: [
        {
            value: SOURCE_TYPES.ASSET,
            label: 'Asset'
        },
        {
            value: SOURCE_TYPES.ASSET_TAGS,
            label: 'Asset Pool'
        },
        {
            value: SOURCE_TYPES.INVENTORY,
            label: 'Variable'
        }
    ],
    [ASSET_TYPES.IMAGE_SEQUENCE]: [
        {
            value: SOURCE_TYPES.ASSET,
            label: 'Asset'
        },
        {
            value: SOURCE_TYPES.ASSET_TAGS,
            label: 'Asset Pool'
        }
    ],
    [ASSET_TYPES.VIDEO_COMPOSITION]: [
        {
            value: SOURCE_TYPES.ASSET,
            label: 'Asset'
        },
        {
            value: SOURCE_TYPES.ASSET_TAGS,
            label: 'Asset Pool'
        }
    ],
    [ASSET_TYPES.IMAGE]: [
        {
            value: SOURCE_TYPES.ASSET,
            label: 'Asset'
        },
        {
            value: SOURCE_TYPES.ASSET_TAGS,
            label: 'Asset Pool'
        },
        {
            value: SOURCE_TYPES.INVENTORY,
            label: 'Variable'
        }
    ],
    [ASSET_TYPES.AUDIO]: [
        {
            value: SOURCE_TYPES.ASSET,
            label: 'Asset'
        },
        {
            value: SOURCE_TYPES.ASSET_TAGS,
            label: 'Asset Pool'
        },
        {
            value: SOURCE_TYPES.INVENTORY,
            label: 'Variable'
        },
        {
            value: SOURCE_TYPES.TEXT,
            label: 'Text'
        }
    ]
};

export const MEDIA_LAYER_SOURCE_TYPE_OPTIONS = [
    {
        value: SOURCE_TYPES.ASSET,
        label: 'Asset'
    },
    {
        value: SOURCE_TYPES.ASSET_TAGS,
        label: 'Asset Pool'
    },
    {
        value: SOURCE_TYPES.INVENTORY,
        label: 'Variable'
    }
];

export const TEMPLATE_LAYER_SOURCE_TYPE_OPTIONS = [
    {
        value: SOURCE_TYPES.ASSET,
        label: 'Asset'
    },
    {
        value: SOURCE_TYPES.ASSET_TAGS,
        label: 'Asset Pool'
    }
];

export const TEMPLATE_TYPES = {
    IMAGE: 'image',
    IMAGE_SEQUENCE: 'image_sequence'
};
export const ASSET_TYPE_OPTIONS = {
    [OVERLAY_TYPES.VIDEO]: [
        {
            value: ASSET_TYPES.VIDEO,
            label: 'Video'
        },
        {
            value: ASSET_TYPES.IMAGE_SEQUENCE,
            label: 'Image Sequence'
        },
        {
            value: ASSET_TYPES.TEMPLATE,
            label: 'Template'
        }
    ],
    [OVERLAY_TYPES.IMAGE]: [
        {
            value: ASSET_TYPES.IMAGE,
            label: 'Image'
        },
        {
            value: ASSET_TYPES.TEMPLATE,
            label: 'Custom Template'
        }
    ]
};

export const RESIZE_TYPES = {
    CROP: 'crop',
    LETTERBOX: 'letterbox',
    BLURBOX: 'blurbox',
    RESPONSIVE: 'responsive',
    STRETCH: 'stretch'
};

export const ENCODING_SETTING_RESIZE_OPTIONS = [
    RESIZE_TYPES.CROP,
    RESIZE_TYPES.LETTERBOX,
    RESIZE_TYPES.RESPONSIVE
];

export const VARIABLE_RESIZE_OPTIONS = [
    RESIZE_TYPES.CROP,
    RESIZE_TYPES.LETTERBOX,
    RESIZE_TYPES.BLURBOX
];

export const LAYER_RESIZE_OPTIONS = [
    {
        label: 'Stretch',
        value: RESIZE_TYPES.STRETCH
    },
    {
        label: 'Fill',
        value: RESIZE_TYPES.CROP
    },
    {
        label: 'Fit',
        value: RESIZE_TYPES.LETTERBOX
    }
];

export const OVERLAY_POSITION_TYPES = {
    RECT: 'StaticRect',
    QUAD: 'StaticQuad',
    MT_RECT: 'MotionTrackRect',
    MT_QUAD: 'MotionTrackQuad'
};

export const OVERLAY_POSITION_TYPE_OPTIONS = {
    [SCENE_TYPES.VIDEO]: [
        {
            value: '',
            label: ''
        },
        {
            value: OVERLAY_POSITION_TYPES.RECT,
            label: 'Rectangle'
        },
        {
            value: OVERLAY_POSITION_TYPES.QUAD,
            label: 'Corner Pin'
        },
        {
            value: OVERLAY_POSITION_TYPES.MT_RECT,
            label: 'Rectangle Sequence'
        },
        {
            value: OVERLAY_POSITION_TYPES.MT_QUAD,
            label: 'Corner Pin Sequence'
        }
    ],
    [SCENE_TYPES.IMAGE]: [
        {
            value: '',
            label: ''
        },
        {
            value: OVERLAY_POSITION_TYPES.RECT,
            label: 'Rectangle'
        },
        {
            value: OVERLAY_POSITION_TYPES.QUAD,
            label: 'Corner Pin'
        }
    ]
};

export const LAYER_POSITION_TYPE_OPTIONS = [
    {
        value: OVERLAY_POSITION_TYPES.RECT,
        label: 'Rectangle'
    },
    {
        value: OVERLAY_POSITION_TYPES.QUAD,
        label: 'Corner Pin'
    },
    {
        value: OVERLAY_POSITION_TYPES.MT_QUAD,
        label: 'Motion Tracked'
    }
];

export const EFFECT_BLEND_MODE_OPTIONS = [
    {
        value: '',
        label: 'Normal'
    },
    {
        value: 'AdditionBlend',
        label: 'Add'
    },
    // {
    //     value: 'DarkenBlend',
    //     label: 'Darken'
    // },
    // {
    //     value: 'DifferenceBlend',
    //     label: 'Difference'
    // },
    // {
    //     value: 'ExclusionBlend',
    //     label: 'Exclusion'
    // },
    // {
    //     value: 'LightenBlend',
    //     label: 'Lighten'
    // },
    {
        value: 'MultiplyBlend',
        label: 'Multiply'
    },
    // {
    //     value: 'OverlayBlend',
    //     label: 'Overlay'
    // },
    {
        value: 'ScreenBlend',
        label: 'Screen'
    }
    // {
    //     value: 'SubtractBlend',
    //     label: 'Subtract'
    // },
    //     {
    //         value: 'XorBlend',
    //         label: 'Xor'
    //    }
];

export const ANIMATION_TYPES = {
    FADE: 'Fade'
};

export const ANIMATION_TYPE_OPTIONS = ['', ANIMATION_TYPES.FADE];

export const EASE_TYPES = {
    LINEAR: 'linear',
    QUAD_OUT: 'quadOut',
    QUAD_IN: 'quadIn',
    QUAD_INOUT: 'quadInOut',
    QUINT_OUT: 'quintOut',
    QUINT_IN: 'quintIn',
    QUINT_INOUT: 'quintInOut',
    CUBIC_IN: 'cubicIn',
    CUBIC_OUT: 'cubicOut',
    CUBIC_INOUT: 'cubicInOut',
    QUART_IN: 'quartIn',
    QUART_OUT: 'quartOut',
    QUART_INOUT: 'quartInOut',
    SINE_IN: 'sineIn',
    SINE_OUT: 'sineOut',
    SINE_INOUT: 'sineInOut',
    EXPO_IN: 'expoIn',
    EXPO_OUT: 'expoOut',
    EXPO_INOUT: 'expoInOut',
    CIRC_IN: 'circIn',
    CIRC_OUT: 'circOut',
    CIRC_INOUT: 'circInOut'
};

export const ANIMATION_EASE_OPTIONS = [
    {
        label: 'linear',
        value: ''
    },
    EASE_TYPES.QUAD_OUT,
    EASE_TYPES.QUAD_IN,
    EASE_TYPES.QUAD_INOUT,
    EASE_TYPES.QUINT_OUT,
    EASE_TYPES.QUINT_IN,
    EASE_TYPES.QUINT_INOUT,
    EASE_TYPES.CUBIC_IN,
    EASE_TYPES.CUBIC_OUT,
    EASE_TYPES.CUBIC_INOUT,
    EASE_TYPES.QUART_IN,
    EASE_TYPES.QUART_OUT,
    EASE_TYPES.QUART_INOUT,
    EASE_TYPES.SINE_IN,
    EASE_TYPES.SINE_OUT,
    EASE_TYPES.SINE_INOUT,
    EASE_TYPES.EXPO_IN,
    EASE_TYPES.EXPO_OUT,
    EASE_TYPES.EXPO_INOUT,
    EASE_TYPES.CIRC_IN,
    EASE_TYPES.CIRC_OUT,
    EASE_TYPES.CIRC_INOUT
];

export const KEYFRAME_EASE_OPTIONS = [
    EASE_TYPES.LINEAR,
    EASE_TYPES.QUAD_OUT,
    EASE_TYPES.QUAD_IN,
    EASE_TYPES.QUAD_INOUT,
    EASE_TYPES.QUINT_OUT,
    EASE_TYPES.QUINT_IN,
    EASE_TYPES.QUINT_INOUT,
    EASE_TYPES.CUBIC_IN,
    EASE_TYPES.CUBIC_OUT,
    EASE_TYPES.CUBIC_INOUT,
    EASE_TYPES.QUART_IN,
    EASE_TYPES.QUART_OUT,
    EASE_TYPES.QUART_INOUT,
    EASE_TYPES.SINE_IN,
    EASE_TYPES.SINE_OUT,
    EASE_TYPES.SINE_INOUT,
    EASE_TYPES.EXPO_IN,
    EASE_TYPES.EXPO_OUT,
    EASE_TYPES.EXPO_INOUT,
    EASE_TYPES.CIRC_IN,
    EASE_TYPES.CIRC_OUT,
    EASE_TYPES.CIRC_INOUT
];

export const PARALLEL_MODE_OPTIONS = [
    {
        label: 'Cut by Cut',
        value: 'cut_by_cut'
    },
    {
        label: 'All Cuts at Once',
        value: 'all_cuts_at_once'
    }
];

export const AWS_S3_CLOUD_REGIONS = [
    'us-east-1',
    'us-east-2',
    'us-west-1',
    'us-west-2',
    'ca-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-west-3',
    'eu-central-1',
    'ap-south-1',
    'ap-northeast-1',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'sa-east-1'
];

export const VIDEO_FORMATS = {
    MP4: 'mp4',
    FLV: 'flv',
    MKV: 'mkv',
    MOV: 'mov',
    WEBM: 'webm'
};

export const ENCODING_SETTING_FORMAT_OPTIONS = [
    VIDEO_FORMATS.MP4,
    VIDEO_FORMATS.FLV,
    VIDEO_FORMATS.MKV,
    VIDEO_FORMATS.MOV,
    VIDEO_FORMATS.WEBM
];

export const ENCODING_SETTING_VIDEO_DEFAULTS = {
    [VIDEO_FORMATS.MP4]: '-c:v h264_nvenc -preset fast -rc:v vbr_hq',
    [VIDEO_FORMATS.FLV]: '-c:v h264_nvenc -preset fast -rc:v vbr_hq',
    [VIDEO_FORMATS.MKV]: '-c:v h264_nvenc -preset fast -rc:v vbr_hq',
    [VIDEO_FORMATS.MOV]: '-c:v prores_ks -pix_fmt yuv422p10le -profile hq',
    [VIDEO_FORMATS.WEBM]: '-c:v libvpx-vp9 -quality good'
};

export const ENCODING_SETTING_AUDIO_DEFAULTS = {
    [VIDEO_FORMATS.MP4]: '-c:a aac -strict -2',
    [VIDEO_FORMATS.FLV]: '-c:a aac -strict -2',
    [VIDEO_FORMATS.MKV]: '-c:a aac -strict -2',
    [VIDEO_FORMATS.MOV]: '-c:a pcm_s16le',
    [VIDEO_FORMATS.WEBM]: '-c:a libvorbis'
};

export const ENCODING_SETTING_PRESETS = {
    'ProRes422 HQ 1920x1080': {
        name: 'ProRes422 HQ 1920x1080',
        video: ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.MOV],
        audio: ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.MOV],
        width: 1920,
        height: 1080,
        resize: RESIZE_TYPES.CROP,
        extension: VIDEO_FORMATS.MOV
    },
    'MP4 H.264 1920x1080': {
        name: 'MP4 H.264 1920x1080',
        video: `${
            ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.MP4]
        } -b:v 4000k -maxrate:v 6000k -bufsize:v 8000k`,
        audio: `${ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.MP4]} -b:a 192k`,
        width: 1920,
        height: 1080,
        resize: RESIZE_TYPES.CROP,
        extension: VIDEO_FORMATS.MP4
    },
    'MP4 H.264 1280x720': {
        name: 'MP4 H.264 1280x720',
        video: `${
            ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.MP4]
        } -b:v 1800k -maxrate:v 2700k -bufsize:v 3600k`,
        audio: `${ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.MP4]} -b:a 192k`,
        width: 1280,
        height: 720,
        resize: RESIZE_TYPES.CROP,
        extension: VIDEO_FORMATS.MP4
    },
    'MP4 H.264 854x480': {
        name: 'MP4 H.264 854x480',
        video: `${
            ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.MP4]
        } -b:v 800k -maxrate:v 1200k -bufsize:v 1600k`,
        audio: `${ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.MP4]} -b:a 192k`,
        width: 854,
        height: 480,
        resize: RESIZE_TYPES.CROP,
        extension: VIDEO_FORMATS.MP4
    },
    'WebM 1920x1080': {
        name: 'WebM 1920x1080',
        video: `${
            ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.WEBM]
        } -b:v 4000k -maxrate:v 6000k -bufsize:v 8000k`,
        audio: `${ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.WEBM]} -qscale:a 5`,
        width: 1920,
        height: 1080,
        resize: RESIZE_TYPES.CROP,
        extension: VIDEO_FORMATS.WEBM
    },
    'WebM 1280x720': {
        name: 'WebM 1280x720',
        video: `${
            ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.WEBM]
        } -b:v 1800k -maxrate:v 2700k -bufsize:v 3600k`,
        audio: `${ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.WEBM]} -qscale:a 5`,
        width: 1280,
        height: 720,
        resize: RESIZE_TYPES.CROP,
        extension: VIDEO_FORMATS.WEBM
    },
    'WebM 854x480': {
        name: 'WebM 854x480',
        video: `${
            ENCODING_SETTING_VIDEO_DEFAULTS[VIDEO_FORMATS.WEBM]
        } -b:v 800k -maxrate:v 1200k -bufsize:v 1600k`,
        audio: `${ENCODING_SETTING_AUDIO_DEFAULTS[VIDEO_FORMATS.WEBM]} -qscale:a 5`,
        width: 854,
        height: 480,
        resize: RESIZE_TYPES.CROP,
        extension: VIDEO_FORMATS.WEBM
    }
};

export const ENCODING_SETTING_PRESET_OPTIONS = [
    {
        label: '--- Select an Output Preset ---',
        value: ''
    },
    {
        label: 'MP4 H.264 1920x1080',
        value: 'MP4 H.264 1920x1080'
    },
    {
        label: 'MP4 H.264 1280x720',
        value: 'MP4 H.264 1280x720'
    },
    {
        label: 'MP4 H.264 854x480',
        value: 'MP4 H.264 854x480'
    },
    {
        label: 'ProRes422 HQ 1920x1080',
        value: 'ProRes422 HQ 1920x1080'
    },
    {
        label: 'WebM 1920x1080',
        value: 'WebM 1920x1080'
    },
    {
        label: 'WebM 1280x720',
        value: 'WebM 1280x720'
    },
    {
        label: 'WebM 854x480',
        value: 'WebM 854x480'
    },
    {
        label: 'New',
        value: 'New'
    }
];

export const ENCODING_SETTING_PRESET_IMAGE = {
    '1920 x 1080': {
        name: '1920 x 1080',
        width: 1920,
        height: 1080,
        quality: 3,
        extension: 'jpg',
        resize: 'crop',
        cut_id: '',
        frame: 0,
        poster: false,
        script: null
    },
    '1280 x 720': {
        name: '1280 x 720',
        width: 1280,
        height: 720,
        quality: 3,
        extension: 'jpg',
        resize: 'crop',
        cut_id: '',
        frame: 0,
        poster: false,
        script: null
    },
    '640 x 480': {
        name: '640 x 480',
        width: 640,
        height: 480,
        quality: 3,
        extension: 'jpg',
        resize: 'crop',
        cut_id: '',
        frame: 0,
        poster: false,
        script: null
    }
};

export const ENCODING_SETTING_PRESET_IMAGE_OPTIONS = [
    {
        label: '--- Select an Output Preset ---',
        value: ''
    },
    {
        label: '1920 x 1080',
        value: '1920 x 1080'
    },
    {
        label: '1280 x 720',
        value: '1280 x 720'
    },
    {
        label: '640 x 480',
        value: '640 x 480'
    },
    {
        label: 'New',
        value: 'New'
    }
];

export const ENCODING_SETTING_PRESET_SPEED_OPTIONS = [
    'fast',
    'slow',
    'medium',
    'hp',
    'hq',
    'bd',
    'll',
    'llhq',
    'llhp',
    'lossless',
    'losslesshp'
];

export const ENCODING_SETTING_VIDEO_PROFILE_OPTIONS = [
    'hq',
    'auto',
    'proxy',
    'It',
    'standard',
    '4444',
    '4444xq'
];

export const ENCODING_SETTING_VIDEO_QUALITY_OPTIONS = ['good', 'best', 'realtime'];

export const ENCODING_SETTING_AUDIO_BITRATE_OPTIONS = ['128', '192', '256', '320'];

export const ENCODING_SETTING_AUDIO_QUALITY_OPTIONS = [
    {
        value: 2,
        label: 'low'
    },
    {
        value: 3,
        label: 'medium'
    },
    {
        value: 5,
        label: 'high'
    },
    {
        value: 8,
        label: 'very high'
    }
];

export const API_REQUEST_TYPES = {
    POST: 'post',
    GET: 'get',
    JSON: 'json'
};

export const API_REQUEST_TYPE_OPTIONS = [
    {
        label: 'POST',
        value: API_REQUEST_TYPES.POST
    },
    {
        label: 'JSON',
        value: API_REQUEST_TYPES.JSON
    },
    {
        label: 'GET',
        value: API_REQUEST_TYPES.GET
    }
];

export const OVERLAY_ENGINE_TYPES = {
    CHROMIUM: 'chromium',
    PHANTOM: ''
};

export const OVERLAY_ENGINE_OPTIONS = [
    {
        value: OVERLAY_ENGINE_TYPES.CHROMIUM,
        label: 'Chromium'
    },
    {
        value: OVERLAY_ENGINE_TYPES.PHANTOM,
        label: 'Phantom'
    }
];

export const IMAGE_ENCODING_SETTING_EXTENSIONS = ['png', 'jpg'];

export const ENCODING_SETTING_QUALITY_OPTIONS = [
    {
        value: 12,
        label: 'Low'
    },
    {
        value: 8,
        label: 'Medium'
    },
    {
        value: 5,
        label: 'High'
    },
    {
        value: 3,
        label: 'Very High'
    }
];

export const COMPOSITION_LAYER_TYPES = {
    IMAGE: 'image',
    VIDEO: 'video',
    TEXT: 'text',
    IMAGE_SEQUENCE: 'image_sequence',
    VIDEO_COMPOSITION: 'video_composition',
    TEMPLATE: 'template',
    AUDIO: 'audio',
    AFTER_EFFECT: 'after_effects',
    HTML: 'html',
    SOLID: 'solid'
};

export const DYNAMIC_DURATION_LAYERS = [
    COMPOSITION_LAYER_TYPES.VIDEO,
    COMPOSITION_LAYER_TYPES.AUDIO,
    COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE,
    COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION
];

export const DEFAULT_FONT = 'Arial';

export const DEFAULT_FONT_WEIGHT = 400;

export const FONT_TYPES = {
    STANDARD: 'standard',
    CUSTOM: 'custom'
};

export const FONT_TYPE_OPTIONS = [
    {
        label: 'Standard',
        value: FONT_TYPES.STANDARD
    },
    {
        label: 'Custom',
        value: FONT_TYPES.CUSTOM
    }
];

export const ATTACHMENT_POINT_OPTIONS = [
    {
        value: 'end_frame',
        label: 'End Frame'
    },
    {
        value: 'start_frame',
        label: 'Start Frame'
    }
];

export const CLIP_DURATION_OPTIONS = [
    {
        value: false,
        label: 'Static'
    },
    {
        value: true,
        label: 'Dynamic'
    }
];

export const DUPLICATABLE_ASSETS = [
    ASSET_TYPES.VIDEO,
    ASSET_TYPES.AUDIO,
    ASSET_TYPES.VIDEO_COMPOSITION
];

export const COMPOSITION_LAYER_COLORS = {
    [COMPOSITION_LAYER_TYPES.AUDIO]: '#48b1de',
    [COMPOSITION_LAYER_TYPES.IMAGE]: '#f1bf15',
    [COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE]: '#f1bf15',
    [COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION]: '#d71616',
    [COMPOSITION_LAYER_TYPES.VIDEO]: '#ec1696',
    [COMPOSITION_LAYER_TYPES.TEMPLATE]: '#d05023',
    [COMPOSITION_LAYER_TYPES.TEXT]: '#47d47d',
    [COMPOSITION_LAYER_TYPES.HTML]: '#ae6cff',
    [COMPOSITION_LAYER_TYPES.SOLID]: '#000000'
};

export const TEXT_LAYER_ANIMATIONS = {
    NONE: '',
    FADE: 'FADE',
    MOVE_LEFT: 'MOVE_LEFT',
    MOVE_RIGHT: 'MOVE_RIGHT',
    MOVE_UP: 'MOVE_UP',
    MOVE_DOWN: 'MOVE_DOWN',
    ZOOM_IN: 'ZOOM_IN',
    BOX_REVEAL: 'BOX_REVEAL'
};

export const TEXT_LAYER_ANIMATION_OPTIONS = [
    TEXT_LAYER_ANIMATIONS.NONE,
    TEXT_LAYER_ANIMATIONS.FADE,
    TEXT_LAYER_ANIMATIONS.MOVE_LEFT,
    TEXT_LAYER_ANIMATIONS.MOVE_RIGHT,
    TEXT_LAYER_ANIMATIONS.MOVE_UP,
    TEXT_LAYER_ANIMATIONS.MOVE_DOWN,
    TEXT_LAYER_ANIMATIONS.ZOOM_IN,
    TEXT_LAYER_ANIMATIONS.BOX_REVEAL
];

export const MAX_FADE_DURATION = 0.5;

export const MAX_REVEAL_DURATION = 1;

export const LOCALE_OPTIONS = [
    'en_US',
    'ca_ES',
    'cs_CZ',
    'cx_PH',
    'cy_GB',
    'da_DK',
    'de_DE',
    'eu_ES',
    'en_UD',
    'es_LA',
    'es_ES',
    'gn_PY',
    'fi_FI',
    'fr_FR',
    'gl_ES',
    'hu_HU',
    'it_IT',
    'ja_JP',
    'ko_KR',
    'nb_NO',
    'nn_NO',
    'nl_NL',
    'fy_NL',
    'pl_PL',
    'pt_BR',
    'pt_PT',
    'ro_RO',
    'ru_RU',
    'sk_SK',
    'sl_SI',
    'sv_SE',
    'th_TH',
    'tr_TR',
    'ku_TR',
    'zh_CN',
    'zh_HK',
    'zh_TW',
    'af_ZA',
    'sq_AL',
    'hy_AM',
    'az_AZ',
    'be_BY',
    'bn_IN',
    'bs_BA',
    'bg_BG',
    'hr_HR',
    'nl_BE',
    'en_GB',
    'et_EE',
    'fo_FO',
    'fr_CA',
    'ka_GE',
    'el_GR',
    'gu_IN',
    'hi_IN',
    'is_IS',
    'id_ID',
    'ga_IE',
    'jv_ID',
    'kn_IN',
    'kk_KZ',
    'lv_LV',
    'lt_LT',
    'mk_MK',
    'mg_MG',
    'ms_MY',
    'mt_MT',
    'mr_IN',
    'mn_MN',
    'ne_NP',
    'pa_IN',
    'sr_RS',
    'so_SO',
    'sw_KE',
    'tl_PH',
    'ta_IN',
    'te_IN',
    'ml_IN',
    'uk_UA',
    'uz_UZ',
    'vi_VN',
    'km_KH',
    'tg_TJ',
    'ar_AR',
    'he_IL',
    'ur_PK',
    'fa_IR',
    'ps_AF',
    'my_MM',
    'qz_MM',
    'or_IN',
    'si_LK',
    'rw_RW',
    'cb_IQ',
    'ha_NG',
    'ja_KS',
    'br_FR',
    'tz_MA',
    'co_FR',
    'as_IN',
    'ff_NG',
    'sc_IT',
    'sz_PL'
];

export const NOTIFICATION_TYPES = {
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
};

export const VIDEO_EXTENSIONS = [
    '3g2',
    '3gp',
    'asf',
    'asx',
    'avi',
    'dvb',
    'f4v',
    'fli',
    'flv',
    'fvt',
    'h261',
    'h263',
    'h264',
    'jpgm',
    'jpgv',
    'jpm',
    'm1v',
    'm2v',
    'm4u',
    'm4v',
    'mj2',
    'mjp2',
    'mk3d',
    'mks',
    'mkv',
    'mng',
    'mov',
    'movie',
    'mp4',
    'mp4v',
    'mpe',
    'mpeg',
    'mpg',
    'mpg4',
    'mxu',
    'ogv',
    'pyv',
    'qt',
    'smv',
    'uvh',
    'uvm',
    'uvp',
    'uvs',
    'uvu',
    'uvv',
    'uvvh',
    'uvvm',
    'uvvp',
    'uvvs',
    'uvvu',
    'uvvv',
    'viv',
    'vob',
    'webm',
    'wm',
    'wmv',
    'wmx',
    'wvx'
];

export const AUDIO_EXTENSIONS = [
    '3gp',
    'aa',
    'aac',
    'aax',
    'aiff',
    'amr',
    'au',
    'dss',
    'iklax',
    'm4a',
    'm4b',
    'm4p',
    'mp3',
    'mpc',
    'msv',
    'ogg',
    'oga',
    'mogg',
    'opus',
    'ra',
    'rm',
    'raw',
    'sln',
    'voc',
    'vox',
    'wav',
    'wma',
    'wv',
    '8svx',
    'cda'
];

export const LAYER_KEYFRAME_INTERPOLATION_TYPES = {
    STATIC: 'STATIC',
    NEAREST_END: 'NEAREST_END',
    PROPORTIONAL: 'PROPORTIONAL'
};

export const LAYER_KEYFRAME_INTERPOLATION_OPTIONS = [
    {
        label: 'Proportional',
        value: LAYER_KEYFRAME_INTERPOLATION_TYPES.PROPORTIONAL
    },
    {
        label: 'Nearest End',
        value: LAYER_KEYFRAME_INTERPOLATION_TYPES.NEAREST_END
    },
    {
        label: 'Static',
        value: LAYER_KEYFRAME_INTERPOLATION_TYPES.STATIC
    }
];

export const onDropLoc = {
    INDICATOR: 'LayerDropIndicator',
    TARGET: 'LayerDropTarget'
};

export const ORIGINS = {
    TOP: 'top',
    LEFT: 'left',
    BOTTOM: 'bottom',
    RIGHT: 'right'
};

export const LAYOUT_UNITS = {
    PIXELS: 'px',
    PERCENT: 'percent'
};

export const TTS_SERVICES = {
    TIKTOK: 'TikTok',
    ELEVENLABS: 'ElevenLabs'
};

export const TTS_VOICES = {
    [TTS_SERVICES.ELEVENLABS]: [
        {
            value: '21m00Tcm4TlvDq8ikWAM',
            label: 'Rachel'
        },
        {
            value: '2EiwWnXFnvU5JabPnv8n',
            label: 'Clyde'
        },
        {
            value: 'AZnzlk1XvdvUeBnXmlld',
            label: 'Domi'
        },
        {
            value: 'CYw3kZ02Hs0563khs1Fj',
            label: 'Dave'
        },
        {
            value: 'D38z5RcWu1voky8WS1ja',
            label: 'Fin'
        },
        {
            value: 'EXAVITQu4vr4xnSDxMaL',
            label: 'Bella'
        },
        {
            value: 'ErXwobaYiN019PkySvjV',
            label: 'Antoni'
        },
        {
            value: 'GBv7mTt0atIp3Br8iCZE',
            label: 'Thomas'
        },
        {
            value: 'IKne3meq5aSn9XLyUdCD',
            label: 'Charlie'
        },
        {
            value: 'LcfcDJNUP1GQjkzn1xUU',
            label: 'Emily'
        },
        {
            value: 'MF3mGyEYCl7XYWbV9V6O',
            label: 'Elli'
        },
        {
            value: 'N2lVS1w4EtoT3dr4eOWO',
            label: 'Callum'
        },
        {
            value: 'ODq5zmih8GrVes37Dizd',
            label: 'Patrick'
        },
        {
            value: 'SOYHLrjzK2X1ezoPC6cr',
            label: 'Harry'
        },
        {
            value: 'TX3LPaxmHKxFdv7VOQHJ',
            label: 'Liam'
        },
        {
            value: 'ThT5KcBeYPX3keUQqHPh',
            label: 'Dorothy'
        },
        {
            value: 'TxGEqnHWrfWFTfGW9XjX',
            label: 'Josh'
        },
        {
            value: 'VR6AewLTigWG4xSOukaG',
            label: 'Arnold'
        },
        {
            value: 'XB0fDUnXU5powFXDhCwa',
            label: 'Charlotte'
        },
        {
            value: 'XrExE9yKIg1WjnnlVkGX',
            label: 'Matilda'
        },
        {
            value: 'Yko7PKHZNXotIFUBG7I9',
            label: 'Matthew'
        },
        {
            value: 'ZQe5CZNOzWyzPSCn5a3c',
            label: 'James'
        },
        {
            value: 'Zlb1dXrM653N07WRdFW3',
            label: 'Joseph'
        },
        {
            value: 'bVMeCyTHy58xNoL34h3p',
            label: 'Jeremy'
        },
        {
            value: 'flq6f7yk4E4fJM5XTYuZ',
            label: 'Michael'
        },
        {
            value: 'g5CIjZEefAph4nQFvHAz',
            label: 'Ethan'
        },
        {
            value: 'jBpfuIE2acCO8z3wKNLl',
            label: 'Gigi'
        },
        {
            value: 'jsCqWAovK2LkecY7zXl4',
            label: 'Freya'
        },
        {
            value: 'oWAxZDx7w5VEj9dCyTzz',
            label: 'Grace'
        },
        {
            value: 'onwK4e9ZLuTAKqWW03F9',
            label: 'Daniel'
        },
        {
            value: 'pMsXgVXv3BLzUgSXRplE',
            label: 'Serena'
        },
        {
            value: 'pNInz6obpgDQGcFmaJgB',
            label: 'Adam'
        },
        {
            value: 'piTKgcLEGmPE4e6mEKli',
            label: 'Nicole'
        },
        {
            value: 't0jbNlBVZ17f02VDIeMI',
            label: 'Jessie'
        },
        {
            value: 'wViXBPUzp2ZZixB1xQuM',
            label: 'Ryan'
        },
        {
            value: 'yoZ06aMxZJJ28mfd3POQ',
            label: 'Sam'
        },
        {
            value: 'z9fAnlkpzviPz146aGWa',
            label: 'Glinda'
        },
        {
            value: 'zcAOhNBS3c14rBihAFp1',
            label: 'Giovanni'
        },
        {
            value: 'zrHiDhphv9ZnVXBqCLjz',
            label: 'Mimi'
        }
    ],
    [TTS_SERVICES.TIKTOK]: [
        {
            label: 'English',
            type: 'group',
            options: [
                {
                    value: 'en_au_001',
                    label: 'English AU - Female'
                },
                {
                    value: 'en_au_002',
                    label: 'English AU - Male'
                },
                {
                    value: 'en_uk_001',
                    label: 'English UK - Male 1'
                },
                {
                    value: 'en_uk_003',
                    label: 'English UK - Male 2'
                },
                {
                    value: 'en_us_001',
                    label: 'English US - Female (Int. 1)'
                },
                {
                    value: 'en_us_002',
                    label: 'English US - Female (Int. 2)'
                },
                {
                    value: 'en_us_006',
                    label: 'English US - Male 1'
                },
                {
                    value: 'en_us_007',
                    label: 'English US - Male 2'
                },
                {
                    value: 'en_us_009',
                    label: 'English US - Male 3'
                },
                {
                    value: 'en_us_010',
                    label: 'English US - Male 4'
                }
            ]
        },
        {
            label: 'Europe',
            type: 'group',
            options: [
                {
                    value: 'fr_001',
                    label: 'French - Male 1'
                },
                {
                    value: 'fr_002',
                    label: 'French - Male 2'
                },
                {
                    value: 'de_001',
                    label: 'German - Female'
                },
                {
                    value: 'de_002',
                    label: 'German - Male'
                },
                {
                    value: 'es_002',
                    label: 'Spanish - Male'
                }
            ]
        },
        {
            label: 'America',
            type: 'group',
            options: [
                {
                    value: 'es_mx_002',
                    label: 'Spanish MX - Male'
                },
                {
                    value: 'br_001',
                    label: 'Portuguese BR - Female 1'
                },
                {
                    value: 'br_003',
                    label: 'Portuguese BR - Female 2'
                },
                {
                    value: 'br_004',
                    label: 'Portuguese BR - Female 3'
                },
                {
                    value: 'br_005',
                    label: 'Portuguese BR - Male'
                }
            ]
        },
        {
            label: 'Asia',
            type: 'group',
            options: [
                {
                    value: 'id_001',
                    label: 'Indonesian - Female'
                },
                {
                    value: 'jp_001',
                    label: 'Japanese - Female 1'
                },
                {
                    value: 'jp_003',
                    label: 'Japanese - Female 2'
                },
                {
                    value: 'jp_005',
                    label: 'Japanese - Female 3'
                },
                {
                    value: 'jp_006',
                    label: 'Japanese - Male'
                },
                {
                    value: 'kr_002',
                    label: 'Korean - Male 1'
                },
                {
                    value: 'kr_003',
                    label: 'Korean - Female'
                },
                {
                    value: 'kr_004',
                    label: 'Korean - Male 2'
                }
            ]
        },
        {
            label: 'Disney',
            type: 'group',
            options: [
                {
                    value: 'en_us_ghostface',
                    label: 'Ghost Face'
                },
                {
                    value: 'en_us_chewbacca',
                    label: 'Chewbacca'
                },
                {
                    value: 'en_us_c3po',
                    label: 'C3PO'
                },
                {
                    value: 'en_us_stitch',
                    label: 'Stitch'
                },
                {
                    value: 'en_us_stormtrooper',
                    label: 'Stormtrooper'
                },
                {
                    value: 'en_us_rocket',
                    label: 'Rocket'
                }
            ]
        },
        {
            label: 'Singing',
            type: 'group',
            options: [
                {
                    value: 'en_female_f08_salut_damour',
                    label: 'Alto'
                },
                {
                    value: 'en_male_m03_lobby',
                    label: 'Tenor'
                },
                {
                    value: 'en_female_f08_warmy_breeze',
                    label: 'Warmy Breeze'
                },
                {
                    value: 'en_male_m03_sunshine_soon',
                    label: 'Sunshine Soon'
                }
            ]
        },
        {
            label: 'Other',
            type: 'group',
            options: [
                {
                    value: 'en_male_narration',
                    label: 'narrator'
                },
                {
                    value: 'en_male_funny',
                    label: 'wacky'
                },
                {
                    value: 'en_female_emotional',
                    label: 'peaceful'
                }
            ]
        }
    ]
};

export const TTS_SERVICE_OPTIONS = [TTS_SERVICES.TIKTOK, TTS_SERVICES.ELEVENLABS];

export const TTS_SERVICE_DEFAULTS = {
    [TTS_SERVICES.ELEVENLABS]: 'onwK4e9ZLuTAKqWW03F9',
    [TTS_SERVICES.TIKTOK]: 'en_us_002'
};

export const AUDIO_FORMATS = {
    MP3: 'mp3',
    OGG: 'ogg',
    M4A: 'm4a',
    WAV: 'wav'
};

export const AUDIO_FORMAT_OPTIONS = [
    AUDIO_FORMATS.MP3,
    AUDIO_FORMATS.OGG,
    AUDIO_FORMATS.M4A,
    AUDIO_FORMATS.WAV
];

export const AUDIO_DEFAULT_SETTNGS = {
    [AUDIO_FORMATS.MP3]: {
        bitrate: 320,
        sample_rate: 44100,
        channels: 2,
        extension: 'mp3'
    },
    [AUDIO_FORMATS.OGG]: {
        bitrate: 320,
        sample_rate: 44100,
        channels: 2,
        extension: 'ogg'
    },
    [AUDIO_FORMATS.M4A]: {
        bitrate: 256,
        sample_rate: 44100,
        channels: 2,
        extension: 'm4a'
    },
    [AUDIO_FORMATS.WAV]: {
        bitrate: 576,
        sample_rate: 44100,
        channels: 2,
        extension: 'wav'
    }
};

export const AUDIO_SAMPLE_RATES = [8000, 11025, 16000, 22050, 32000, 44100, 48000];

export const AUDIO_BITRATES = {
    [AUDIO_FORMATS.MP3]: [192, 224, 256, 320],
    [AUDIO_FORMATS.OGG]: [128, 144, 160, 192, 224, 256, 320],
    [AUDIO_FORMATS.M4A]: [64, 80, 96, 112, 128, 144, 160, 192, 224, 256],
    [AUDIO_FORMATS.WAV]: [128, 144, 160, 192, 224, 256, 320, 384, 448, 512, 576]
};

export const AUDIO_CHANNEL_OPTIONS = [
    {
        label: 'Mono',
        value: 1
    },
    {
        label: 'Stereo',
        value: 2
    }
];

export const ENCODING_SETTING_PRESET_AUDIO_OPTIONS = [
    {
        label: '--- Select an Output Preset ---',
        value: ''
    },
    {
        label: 'MP3 44100kHz 320kB',
        value: 'MP3 44100kHz 320kB'
    },
    {
        label: 'OGG 44100kHz 320kB',
        value: 'OGG 44100kHz 320kB'
    },
    {
        label: 'M4A 44100kHz 256kB',
        value: 'M4A 44100kHz 256kB'
    },
    {
        label: 'WAV 44100kHz 576kB',
        value: 'WAV 44100kHz 576kB'
    },
    {
        label: 'New',
        value: 'New'
    }
];

export const ENCODING_SETTING_PRESET_AUDIO = {
    'MP3 44100kHz 320kB': {
        name: 'MP3 44100kHz 320kB',
        ...AUDIO_DEFAULT_SETTNGS[AUDIO_FORMATS.MP3]
    },
    'OGG 44100kHz 320kB': {
        name: 'OGG 44100kHz 320kB',
        ...AUDIO_DEFAULT_SETTNGS[AUDIO_FORMATS.OGG]
    },
    'M4A 44100kHz 256kB': {
        name: 'M4A 44100kHz 256kB',
        ...AUDIO_DEFAULT_SETTNGS[AUDIO_FORMATS.M4A]
    },
    'WAV 44100kHz 576kB': {
        name: 'WAV 44100kHz 576kB',
        ...AUDIO_DEFAULT_SETTNGS[AUDIO_FORMATS.WAV]
    }
};

export const TEMPLATE_PREVIEW_EVENTS = {
    PRE_INIT: 'IMPOSIUM_TEMPLATE_PRE_INIT',
    LOADED: 'IMPOSIUM_TEMPLATE_IFRAME_READY',
    CAPTURE_READY: 'IMPOSIUM_CAPTURE_READY'
};

export const NEW_PROJECT_PRESET_TEMPLATE_OPTIONS = [
    {
        label: '--- Select Project Template ---',
        value: ''
    },
    {
        label: 'Blank Template',
        value: 'Blank Template'
    }
];

export const MEDIA_VARIABLE_TYPES = [
    VARIABLE_TYPES.IMAGE,
    VARIABLE_TYPES.VIDEO,
    VARIABLE_TYPES.AUDIO
];

export const RESERVED_VARIABLE_IDS = [
    'notification_email',
    'version-name',
    'clickTag1',
    'version_list_id',
    'creative_id',
    'creative_library_id',
    'version-id',
    'version-video-url'
];
