export const HEADER_HEIGHT: number = 30;

export const LEFT_PANEL_MIN: number = 400;

export const LEFT_PANEL_MAX: string | number = '75%';

export const LEFT_PANEL_DEFAULT: number = 704;

export const LEFT_PANEL_EXPANDED: string = '65%';

export const ASSET_TABLE_DEFAULT: string = '50%';

export const ASSET_TABLE_EXPANDED: string = '100%';

export const LEFT_PANEL_DEFAULT_IMG: number = 800;

export const LEFT_PANEL_MAX_IMG: number = 1200;

export const TIMELINE_MIN: number = 150;

export const TIMELINE_MAX: number = 600;

export const TIMELINE_DEFAULT: number = 250;

export const LAYERED_TIMELINE_DEFAULT: number = 325;

export const VAR_PANEL_DEFAULT: number = 300;

export const ASSET_DISTRO = 'https://d188rqu2y6x0ew.cloudfront.net';

export const ASSET_ORIGIN = 'https://imposium-footage.s3.us-east-1.amazonaws.com';

export const DEFAULT_DESYNC_TOLERANCE = 1 / 90;

export const RETRY_STATUSES: any = [206];

export const MAX_ATTEMPTS = 2;

export const RETRY_DELAY = 100;

export const MULTI_SELECT_EVENT_NAME = 'multiselectinteractionend;';

export const LAYER_DRAW_COMPLETE = 'layerdrawcomplate';

export const FORCE_UPDATE_HTML_ASSET = 'forceupdatehtmlasset';

export const FORCE_UPDATE_HTML_EDITOR = 'forceupdatehtmleditor';

export const RESERVED_COMP_RENDER_NAME = 'RENDER';

export const RIGHT_PANEL_OPTIONS: any = [
    {
        label: 'assets',
        value: 'Assets'
    },
    {
        label: 'variables',
        value: 'Variables'
    }
];

export const NOTIFICATION_BUTTONS: any = {
    ERROR_VIEWER: 'ERROR_VIEWER'
};

export const PREVIEW_MAX_WIDTH: number = 400;
export const PREVIEW_MAX_HEIGHT: number = 400;

export const PROJECT_SETTINGS_TABS = {
    PROJECT: 'project',
    IMAGE_OUTPUT: 'image-output',
    MODERATION: 'moderation',
    INTEGRATION: 'integration',
    CONFIRM_SETTINGS: 'confirm-settings',
    ADVANCED: 'advanced',
    VIDEO: 'video',
    IMAGE: 'image',
    AUDIO: 'audio',
    PLAYER: 'player'
};

export const POST_SETTINGS_ACTIONS = {
    PREVIEW: 'preview',
    PUBLISH: 'publish'
};

export const LAYER_SOURCE_STATUSES = {
    LOADING: 'LOADING',
    NO_SOURCE: 'NO_SOURCE',
    NO_PREVIEW: 'NO_PREVIEW',
    ERROR: 'ERROR',
    READY: 'READY',
    CIRCULAR_COMP: 'CIRCULAR_COMP'
};

export const MAX_OUTPUT_WIDTH: number = 3840;

export const MAX_OUTPUT_HEIGHT: number = 2180;

export const MIN_OUTPUT_DIMENSION: number = 146;

export const MP4_MIN_OUTPUT_DIMENSION: number = 16;

export const MIN_VIDEO_BITRATE: number = 500;

export const MIN_CODE_PANEL_WIDTH: number = 250;

export const MIN_ZOOM = 0.1;

export const MAX_ZOOM = 3;

export const ZOOM_LEVELS = [0.1, 0.25, 0.5, 1, 1.5, 2, 3];

export const CODE_EDITOR_TABS = {
    CODE: 'code',
    PREVIEW: 'preview'
};

export const LOWER_PANELS = {
    TIMELINE: 'TIMELINE',
    DATA: 'DATA'
};

export const PROP_KEY_PRFIX = '$PROP';
