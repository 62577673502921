import {
    IVariable,
    NEW_VARIABLE,
    NEW_VARIABLE_DEFAULT,
    IAPIScene,
    NEW_API_SCENE,
    NEW_PROJECT,
    IStory,
    NEW_ACT,
    NEW_VIDEO_SCENE,
    NEW_OVERLAY,
    IOverlay,
    NEW_CUT,
    ICut,
    IAssetSource,
    NEW_ASSET_SOURCE,
    IAudioOverlay,
    NEW_AUDIO_OVERLAY,
    NEW_IMAGE_SCENE,
    NEW_COMPOSITION_SCENE,
    NEW_COMPOSITION_LAYER,
    NEW_RECT_POSITION,
    NEW_MEDIA_LAYER_OPTIONS,
    NEW_TEXT_LAYER_OPTIONS,
    NEW_TEMPLATE_LAYER_OPTIONS,
    NEW_COMPOSITION,
    ILayeredComposition,
    NEW_VIDEO_DEFAULT_VIEWER,
    NEW_EMPTY_DEFAULT_VIEWER,
    NEW_HTML_LAYER_OPTIONS,
    NEW_API_SCENE_DATA,
    IAPISceneData,
    NEW_SOLID_LAYER_OPTIONS,
    NEW_ASSET_SOLID_SOURCE,
    NEW_TTS_CONFIG
} from '../constants/snippets';

import {
    ASSET_TYPES,
    COMPOSITION_LAYER_TYPES,
    OVERLAY_POSITION_TYPES,
    RATE_OPTIONS,
    SCENE_TYPES,
    SOURCE_TYPES,
    TAG_TYPES,
    VARIABLE_TYPES
} from '../constants/story';

import { MAX_OUTPUT_HEIGHT, MAX_OUTPUT_WIDTH } from '../constants/editor';
import moize from 'moize';
import { between } from './string';
import { capitalize } from './general';
import store from '../redux/store';
import { uploadAssets, validateAssetMimetype } from '@imposium-hub/components';
import { MAX_FILE_SIZE, api } from '../constants/app';
import { logError } from './notifications';
import { updateEditorConfig, uploadHTMLAsset } from '../redux/actions/editor';
import { assets } from '../constants/copy';

export const toFixed = (num: number, fixed: number) => {
    const re = new RegExp(`^-?\\d+(?:\.\\d{0,${fixed || -1}})?`);
    return num.toString().match(re)[0];
};

export const getStoryType = (story): string => {
    const act = getFirstAct(story);
    const scene = getFirstScene(act, [
        SCENE_TYPES.IMAGE,
        SCENE_TYPES.VIDEO,
        SCENE_TYPES.COMPOSITION
    ]);
    return scene.type;
};

export const getFirstAct = (story): any => {
    for (const key in story.acts) {
        if (story.acts.hasOwnProperty(key)) {
            return story.acts[key];
        }
    }
};

export const getFirstScene = (act, types): any => {
    for (const key in act.scenes) {
        if (act.scenes.hasOwnProperty(key)) {
            if (types) {
                if (types.indexOf(act.scenes[key].type) !== -1) {
                    return act.scenes[key];
                }
            } else {
                return act.scenes[key];
            }
        }
    }
};

// TODO: make this get layers
export const getMaxOverlays = (cuts): any => {
    let maxOverlays = 0;

    for (const cut of cuts) {
        const overlays = cut.overlays;
        if (overlays) {
            if (overlays.length > maxOverlays) {
                maxOverlays = overlays.length;
            }
        }
    }
    return maxOverlays;
};

export const getAudioOverlays = (audioOverlays): any => {
    if (audioOverlays) {
        return audioOverlays.length;
    } else {
        return 0;
    }
};

export const getCutIndex = (cuts, id): any => {
    for (const cut of cuts) {
        if (cut.id === id) {
            return cuts.indexOf(cut);
        }
    }
};

export const getOverlayIndex = (cut, id): any => {
    for (const overlay of cut.overlays) {
        if (overlay.id === id) {
            return cut.overlays.indexOf(overlay);
        }
    }
};

export const getCut = (cuts, id): any => {
    for (const cut of cuts) {
        if (cut.id === id) {
            return cut;
        }
    }
};

export const getLayer = (layers, id): any => {
    return layers.find((layer) => {
        return layer.id === id;
    });
};

export const getLayerIndex = (layers, id): any => {
    return layers.findIndex((layer) => {
        return layer.id === id;
    });
};

export const isVisibleLayer = (type): boolean => {
    if (type === COMPOSITION_LAYER_TYPES.AUDIO) {
        return false;
    }
    return true;
};

export const isAudioLayer = (type): boolean => {
    if (
        type === COMPOSITION_LAYER_TYPES.AUDIO ||
        type === COMPOSITION_LAYER_TYPES.VIDEO ||
        type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION
    ) {
        return true;
    }
    return false;
};

export const doesCutFit = (cuts, cut): boolean => {
    const { startFrame, endFrame } = cut;

    for (const c of cuts) {
        // don't check the cut we're moving
        if (c.id !== cut.id) {
            if (startFrame) {
                if (startFrame > c.startFrame && startFrame < c.endFrame) {
                    return false;
                }
            }
            if (endFrame) {
                if (endFrame > c.startFrame && endFrame < c.endFrame) {
                    return false;
                }
            }
        }
    }
    return true;
};

export const getVariableOptions = (variables, include = []): any => {
    const variableOptions = [
        {
            label: '',
            value: ''
        }
    ];

    for (const key in variables) {
        if (variables.hasOwnProperty(key)) {
            const variable = variables[key];

            if (include.indexOf(variable.type.toLowerCase()) !== -1 || include.length === 0) {
                variableOptions.push({
                    label: variable.name,
                    value: variable.id
                });
            }
        }
    }
    return variableOptions;
};

export const getVariableVersions = (variables, id): any[] => {
    const variableVersions = [
        {
            label: '',
            value: ''
        }
    ];

    const variable = variables[id];

    if (variable) {
        if (variable.versions) {
            for (const version of variable.versions) {
                variableVersions.push({
                    label: version.name,
                    value: version.id
                });
            }
        }
    }

    return variableVersions;
};

export const generateUUID = (): string => {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return uuid;
};

export const newVariable = (type: string): IVariable => {
    const variable: IVariable = { ...NEW_VARIABLE };
    variable.type = type;
    variable.defaultItem = { ...NEW_VARIABLE_DEFAULT };
    variable.defaultItem.type = type;
    variable.previewItem = { ...NEW_VARIABLE_DEFAULT };
    variable.previewItem.type = type;

    if (type === VARIABLE_TYPES.VIDEO) {
        const versions = [];
        variable.versions = versions;
    }

    return variable;
};

export const newOverlay = (): IOverlay => {
    const overlay: IOverlay = { ...NEW_OVERLAY };
    const source: IAssetSource = { ...NEW_ASSET_SOURCE };
    source.asset_type = ASSET_TYPES.IMAGE;
    overlay.source = source;

    overlay.id = generateUUID();

    return overlay;
};

export const newStory = (type): IStory => {
    const story = { ...NEW_PROJECT };
    story.id = generateUUID();

    const act = { ...NEW_ACT };
    act.id = generateUUID();

    let scene;
    let viewer;

    switch (type) {
        case SCENE_TYPES.VIDEO:
            scene = { ...NEW_VIDEO_SCENE };
            viewer = { ...NEW_VIDEO_DEFAULT_VIEWER };
            break;
        case SCENE_TYPES.IMAGE:
            scene = { ...NEW_IMAGE_SCENE };
            viewer = { ...NEW_EMPTY_DEFAULT_VIEWER };
            break;
        case SCENE_TYPES.COMPOSITION:
            scene = { ...NEW_COMPOSITION_SCENE };
            viewer = { ...NEW_EMPTY_DEFAULT_VIEWER };
            break;
    }
    scene.id = generateUUID();

    act.scenes = {
        [scene.id]: scene
    };

    story.acts = {
        [act.id]: act
    };

    story.viewer = viewer;

    return story;
};

export const newCut = (startFrame): ICut => {
    const cut = { ...NEW_CUT };

    cut.id = generateUUID();
    cut.startFrame = startFrame;
    cut.endFrame = startFrame + 1;

    return cut;
};

export const newAudioOverlay = (): IAudioOverlay => {
    const overlay = { ...NEW_AUDIO_OVERLAY };
    overlay.id = generateUUID();

    const source = { ...NEW_ASSET_SOURCE };
    source.asset_type = ASSET_TYPES.AUDIO;
    overlay.source = source;

    return overlay;
};

export const newAPIScene = (
    videoSceneId: string,
    name?: string,
    isIntegration?: boolean
): IAPIScene => {
    const scene: IAPIScene = { ...NEW_API_SCENE };
    const data: IAPISceneData = { ...NEW_API_SCENE_DATA };

    scene.id = generateUUID();

    if (name) {
        scene.name = name;
    }
    data.videoItem = videoSceneId;

    if (isIntegration) {
        data.isIntegration = true;
    }

    scene.sceneData = data;
    return scene;
};

export const duplicateStory = (story: IStory): IStory => {
    const nStory = { ...story };
    nStory.id = generateUUID();
    nStory.name = `Copy of ${story.name}`;

    const act = { ...getFirstAct(nStory) };
    act.id = generateUUID();

    const scenes = { ...act.scenes };
    const approvedScene = story.moderationApprovedScene;
    const rejectedScene = story.moderationRejectedScene;

    const newScenes = {};
    let mediaId;

    // change out the scene IDs
    for (const sceneId in act.scenes) {
        if (act.scenes.hasOwnProperty(sceneId)) {
            const scene = { ...scenes[sceneId] };
            scene.id = generateUUID();

            if (sceneId === approvedScene) {
                nStory.moderationApprovedScene = scene.id;
            } else if (sceneId === rejectedScene) {
                nStory.moderationRejectedScene = scene.id;
            }

            if (scene.type === SCENE_TYPES.VIDEO || scene.type === SCENE_TYPES.IMAGE) {
                mediaId = scene.id;
            }

            newScenes[scene.id] = scene;
        }
    }

    // replace the videoItem sceneIds in any API scenes
    for (const sceneId in newScenes) {
        if (newScenes.hasOwnProperty(sceneId)) {
            const scene = { ...newScenes[sceneId] };

            if (scene.type === SCENE_TYPES.API) {
                const newSceneData = { ...scene.sceneData };
                newSceneData.videoItem = mediaId;
                scene.sceneData = newSceneData;
                newScenes[scene.id] = scene;
            }
        }
    }

    act.scenes = newScenes;
    const acts = {
        [act.id]: act
    };
    nStory.acts = acts;
    return nStory;
};

export const experienceFormData = (storyId: string, inventory: any): any => {
    const formData = new FormData();

    formData.append('story_id', storyId);

    for (const key in inventory) {
        if (inventory[key]) {
            const data: any = inventory[key];

            if (data && data.type === 'file') {
                // Deal with HTML5 File inputs, only accept one currently
                const { files } = data;

                if (files.length > 0) {
                    inventory[key] = '';
                    formData.append(key, files[0]);
                } else {
                    inventory[key] = '';
                }
            } else if ((data && data instanceof Blob) || data instanceof File) {
                // Deal with blobs && pre-parsed HTML5 File objects
                inventory[key] = '';
                formData.append(key, data, 'inventory.png');
            }

            // Add other inputs, for files this will just be a key that our API uses for a look up
            formData.append(`inventory[${key}]`, inventory[key]);
        }
    }

    return formData;
};

export const convertAE = (data: string): string => {
    const tlFrames = formatFrames(between(data, 'Left #2', 'Effects'));
    const trFrames = formatFrames(between(data, 'Right #3', 'Effects'));
    const brFrames = formatFrames(
        between(data, 'Right #5', 'Transform') || between(data, 'Right #5', 'End')
    );
    const blFrames = formatFrames(between(data, 'Left #4', 'Effects'));
    const posFrames = formatFrames(
        between(data, 'Position', 'Transform') || between(data, 'Position', 'End')
    );
    const anchorFrames = formatFrames(
        between(data, 'Anchor Point', 'Transform') || between(data, 'Anchor Point', 'End')
    );

    let coords = '';

    for (let index = 0; index < tlFrames.length; index++) {
        const anchor = anchorFrames[index] || anchorFrames[0] || '\t\t0\t0';
        const pos = posFrames[index] || posFrames[0] || '\t\t0\t0';

        const tl = frameCoords(tlFrames[index], pos, anchor);
        const tr = frameCoords(trFrames[index], pos, anchor);
        const br = frameCoords(brFrames[index], pos, anchor);
        const bl = frameCoords(blFrames[index], pos, anchor);

        coords += `${tl.x},${tl.y},${tr.x},${tr.y},${br.x},${br.y},${bl.x},${bl.y},`;
    }

    coords = coords.slice(0, -1);
    coords = coords.replace(/\s/g, '');

    return coords;
};

const frameCoords = (frameData, posData, anchorData): any => {
    const frame = frameData.split(/\r?\t/);
    const pos = posData.split(/\r?\t/);
    const anchor = anchorData.split(/\r?\t/);

    const frameX = frame[2];
    const frameY = frame[3];
    const posX = pos[2];
    const posY = pos[3];
    const anchorX = anchor[2];
    const anchorY = anchor[3];

    return {
        x: (posX - (anchorX - frameX)).toFixed(3),
        y: (posY - (anchorY - frameY)).toFixed(3)
    };
};

const formatFrames = (data): any => {
    const d = data.split(/\r?\n/);

    d.shift();
    d.shift();
    d.pop();
    d.pop();

    return d;
};

export const convertMocha = (data: string): string => {
    const pinData = [];
    const transformScale = 1;

    pinData.push(between(data, 'Pin-0001', 'Effects'));
    pinData.push(between(data, 'Pin-0002', 'Effects'));
    pinData.push(between(data, 'Pin-0004', 'End'));
    pinData.push(between(data, 'Pin-0003', 'Effects'));

    for (let i = 0; i < 4; i++) {
        pinData[i] = pinData[i].split('\n');
        pinData[i].shift();
        pinData[i].shift();
        pinData[i].pop();
        pinData[i].pop();
    }

    let coords = '';
    for (let index = 0; index < pinData[0].length; index++) {
        for (let i = 0; i < 4; i++) {
            const arr = pinData[i][index].match(/\S+/g);
            coords += Number(Number(arr[1]) * transformScale).toFixed(2);
            coords += ',';
            coords += Number(Number(arr[2]) * transformScale).toFixed(2);
            coords += ',';
        }
    }

    // trim off the last comma
    coords = coords.slice(0, -1);
    coords = coords.replace(/\s/g, '');

    return coords;
};

export const scaleCoords = (data: string, scale: number): string => {
    const coords = data.split(',');
    let newCoords = '';

    for (let i = 0; i < coords.length; i++) {
        const transformedCoord = (parseFloat(coords[i]) * scale).toFixed(2);
        newCoords += transformedCoord;

        if (i !== coords.length - 1) {
            newCoords += ',';
        }
    }

    return newCoords;
};

export const countCoordsFrames = (data: string): number => {
    const coords = data.split(',');
    return coords.length / 8;
};

export const newTTSAudioLayer = (
    type: string,
    width: number,
    height: number,
    compWidth: number,
    compHeight: number,
    frames: number,
    text: string,
    name?: string
) => {
    const layer = { ...NEW_COMPOSITION_LAYER };
    layer.type = type;
    layer.id = generateUUID();

    layer.name = name ? name : `New ${capitalize(type)} Layer`;

    layer.position = OVERLAY_POSITION_TYPES.RECT;
    const pos = { ...NEW_RECT_POSITION };
    pos.x = compWidth / 2;
    pos.y = compHeight / 2;
    pos.width = width;
    pos.height = height;
    pos.anchorX = width / 2;
    pos.anchorY = height / 2;
    layer.position_inputs = pos;

    layer.start_frame = 0;
    layer.end_frame = frames;

    const options = { ...NEW_MEDIA_LAYER_OPTIONS };
    const source = { from: SOURCE_TYPES.TEXT, text, text_to_speech: NEW_TTS_CONFIG };

    options.source = source;

    layer.options = options;

    return layer;
};

export const newCompositionLayer = (
    type: string,
    width: number,
    height: number,
    compWidth: number,
    compHeight: number,
    frames: number,
    name?: string
) => {
    const layer = { ...NEW_COMPOSITION_LAYER };
    let options;
    let source;

    layer.type = type;

    layer.id = generateUUID();

    layer.name = name ? name : `New ${capitalize(type)} Layer`;

    layer.position = OVERLAY_POSITION_TYPES.RECT;
    const pos = { ...NEW_RECT_POSITION };
    if (type === COMPOSITION_LAYER_TYPES.TEXT) {
        pos.x = compWidth / 2;
        pos.y = compHeight / 2;
        pos.width = width;
        pos.height = 250;
        pos.anchorX = width / 2;
        pos.anchorY = 125;
    } else {
        pos.x = compWidth / 2;
        pos.y = compHeight / 2;
        pos.width = width;
        pos.height = height;
        pos.anchorX = width / 2;
        pos.anchorY = height / 2;
    }
    layer.position_inputs = pos;

    layer.start_frame = 0;
    layer.end_frame = frames;

    layer.keyframes = {
        opacity: [],
        position: [],
        anchor: [],
        scale: [],
        volume: [],
        size: []
    };

    switch (type) {
        case COMPOSITION_LAYER_TYPES.VIDEO:
            options = { ...NEW_MEDIA_LAYER_OPTIONS };
            source = { ...NEW_ASSET_SOURCE };
            source.asset_type = ASSET_TYPES.VIDEO;
            options.source = source;
            break;
        case COMPOSITION_LAYER_TYPES.TEXT:
            options = { ...NEW_TEXT_LAYER_OPTIONS };
            break;
        case COMPOSITION_LAYER_TYPES.IMAGE:
            options = { ...NEW_MEDIA_LAYER_OPTIONS };
            source = { ...NEW_ASSET_SOURCE };
            source.asset_type = ASSET_TYPES.IMAGE;
            options.source = source;
            break;
        case COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE:
            options = { ...NEW_MEDIA_LAYER_OPTIONS };
            source = { ...NEW_ASSET_SOURCE };
            source.asset_type = ASSET_TYPES.IMAGE_SEQUENCE;
            options.source = source;
            break;
        case COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION:
            options = { ...NEW_MEDIA_LAYER_OPTIONS };
            source = { ...NEW_ASSET_SOURCE };
            source.asset_type = ASSET_TYPES.VIDEO_COMPOSITION;
            options.source = source;
            break;
        case COMPOSITION_LAYER_TYPES.AUDIO:
            options = { ...NEW_MEDIA_LAYER_OPTIONS };
            source = { ...NEW_ASSET_SOURCE };
            source.asset_type = ASSET_TYPES.AUDIO;
            options.source = source;
            break;
        case COMPOSITION_LAYER_TYPES.TEMPLATE:
            options = { ...NEW_TEMPLATE_LAYER_OPTIONS };
            options.width = width;
            options.height = height;
            source = { ...NEW_ASSET_SOURCE };
            source.asset_type = ASSET_TYPES.TEMPLATE;
            options.source = source;
            break;
        case COMPOSITION_LAYER_TYPES.HTML:
            options = { ...NEW_HTML_LAYER_OPTIONS };
            options.width = width;
            options.height = height;
            source = { ...NEW_ASSET_SOURCE };
            source.asset_type = ASSET_TYPES.HTML;
            options.source = source;
            break;
        case COMPOSITION_LAYER_TYPES.SOLID:
            options = { ...NEW_SOLID_LAYER_OPTIONS };
            options.width = width;
            options.height = height;
            source = { ...NEW_ASSET_SOLID_SOURCE };
            options.source = source;
            break;
    }

    layer.options = options;

    return layer;
};

export const parseInventoryFromVariables = (variables, variableMap?) => {
    const inv = {};
    const newVars = variableMap ? applyVariableMapping(variables, variableMap) : { ...variables };
    for (const key in variables) {
        if (newVars.hasOwnProperty(key)) {
            const variable = newVars[key];

            // if the variable is a string, this means we're actually parsing inventory
            if (typeof variable === 'object') {
                if (
                    variable.type === VARIABLE_TYPES.IMAGE ||
                    variable.type === VARIABLE_TYPES.AUDIO ||
                    variable.type === VARIABLE_TYPES.VIDEO ||
                    !variable.type
                ) {
                    if (newVars[key].previewItem) {
                        inv[key] = newVars[key].previewItem.url;
                    } else {
                        inv[key] = newVars[key];
                    }
                } else {
                    inv[key] = newVars[key].previewItem.src || '';
                }
            } else {
                inv[key] = variable;
            }
        }
    }
    return inv;
};

export const parseInventoryFromRowData = (headers, rowData) => {
    const inv = {};
    for (let i = 0; i < headers.length; i++) {
        const header = headers[i];
        const rowVal = rowData[i];
        inv[header] = rowVal;
    }
    return inv;
};

export const getTagFilter = (config, inventory) => {
    let filter = '';
    for (let i = 0; i < config.length; i++) {
        const tag = config[i];
        if (tag.type === TAG_TYPES.TEXT) {
            filter += tag.value;
        } else if (tag.type === TAG_TYPES.VARIABLE) {
            filter += inventory[tag.value];
        }
        if (i < config.length - 1) {
            filter += ',';
        }
    }
    return filter;
};

export const getCompFromAsset = (asset): ILayeredComposition => {
    const {
        name,
        id,
        width: assetWidth,
        height: assetHeight,
        rate: assetRate,
        type,
        frame_count: assetFrameCount
    } = asset;
    if (type === ASSET_TYPES.IMAGE || type === ASSET_TYPES.VIDEO) {
        const rate = assetRate ? assetRate : NEW_COMPOSITION.rate;
        const frame_count = assetFrameCount ? assetFrameCount : 24;

        let width = assetWidth;
        let height = assetHeight;

        if (width > MAX_OUTPUT_WIDTH || height > MAX_OUTPUT_HEIGHT) {
            const aspect = width / height;
            if (width > height) {
                width = MAX_OUTPUT_WIDTH;
                height = width * aspect;
            } else {
                height = MAX_OUTPUT_HEIGHT;
                width = height / aspect;
            }
        }

        const layer = newCompositionLayer(type, width, height, width, height, frame_count);
        const src = { ...NEW_ASSET_SOURCE };
        src.asset_type = type;
        src.asset_id = id;
        layer.options.source = src;
        layer.name = name;

        const comp: ILayeredComposition = { ...NEW_COMPOSITION };
        comp.name = name;
        comp.rate = getFramerate(rate);
        comp.frames = frame_count;
        comp.width = width;
        comp.height = height;
        comp.layers = [layer];

        return comp;
    } else {
        return null;
    }
};

export const getCompFromLayers = (
    selectedLayers: any[],
    activeComp: ILayeredComposition,
    compName: string
): ILayeredComposition => {
    const { rate, frames, width, height, background_color } = activeComp;
    const comp: ILayeredComposition = { ...NEW_COMPOSITION };
    comp.name = compName;
    comp.background_color = background_color;
    comp.rate = rate;
    comp.frames = frames;
    comp.width = width;
    comp.height = height;
    comp.layers = [...selectedLayers];

    return comp;
};

export const getFramerate = (rate) => {
    return RATE_OPTIONS.reduce((acc, obj) =>
        Math.abs(rate - obj.value) < Math.abs(rate - acc.value) ? obj : acc
    ).value;
};

export const getLayerOptions = (layers, exclude = []): any => {
    const opts = [
        {
            value: null,
            label: ''
        }
    ];
    for (const layer of layers) {
        if (exclude.indexOf(layer.id) === -1) {
            opts.push({
                value: layer.id,
                label: layer.name
            });
        }
    }
    return opts;
};

export const matchType = (markup, type, requiredType: string[] | string): any => {
    if (requiredType.indexOf(type) !== -1) {
        return markup;
    } else {
        return null;
    }
};

export const getLayerFromAsset = (asset, w, h, totalFrames, rate?) => {
    let width = w;
    let height = h;
    let frameCount = totalFrames;
    let data = null;

    if (asset?.data) {
        data = JSON.parse(asset.data);
    }

    switch (asset.type) {
        case ASSET_TYPES.VIDEO:
            frameCount = asset.frame_count;
            width = asset.width;
            height = asset.height;
            break;
        case ASSET_TYPES.IMAGE:
            width = asset.width;
            height = asset.height;
            break;
        case ASSET_TYPES.IMAGE_SEQUENCE:
            frameCount = asset.duration;
            break;
        case ASSET_TYPES.VIDEO_COMPOSITION:
            frameCount = data.frames;
            width = data.width;
            height = data.height;
            break;
        case ASSET_TYPES.AUDIO:
            frameCount = Math.round(asset.duration * rate);
            break;
        case ASSET_TYPES.TEMPLATE:
            break;
        case ASSET_TYPES.HTML:
            const {
                devConfig: { frames }
            } = data;
            frameCount = frames;
            break;
        default:
            // TODO: throw error
            return;
    }

    const src = { ...NEW_ASSET_SOURCE };
    src.asset_type = asset.type;
    src.asset_id = asset.id;

    const layer = newCompositionLayer(asset.type, width, height, w, h, frameCount);
    layer.options.source = src;
    layer.name = asset.name;

    return layer;
};

export const newHTMLAsset = (inventory, map) => {
    return {
        devConfig: {
            type: 'image_sequence',
            assets: JSON.stringify(map.assets),
            fps: 25,
            frames: 64,
            frame: 1,
            width: 1920,
            height: 1080,
            background_color: '#FFFFFF',
            inventory
        },
        javascript: `// Preload all fonts here, by setting their name in the 'families' array. This ensures all web fonts are loaded before the template renders.
TemplateEngine.webFontConfig = {
    custom: {
        families: ['Poppins-Bold']
    }
};

//TemplateEngine.initTemplate(); is called once the template is ready to run your custom logic
TemplateEngine.initTemplate = ()=>{

    const textContainer = document.getElementById('text-container');
    const colorOverlay = document.getElementById('color-overlay');
    // SAMPLE TEMPLATE LOGIC:

    // - Access variables passed in via IMPOSIUM_INVENTORY using the variable ID.
    // const text1 = IMPOSIUM_INVENTORY['text1'];
    const text1 = 'Hello World!';

    // - Perform all DOM manipulations needed to add the dynamic data into the HTML
    textContainer.append(text1);

    // - Add some animation to the global timeline (if this is an image_sequence template) by adding GSAP tweens.
    // - TemplateEngine.timeline is a GSAP timeline. Docs: https://greensock.com/docs/
    
    const totalAnimationDuration = 0.8;
    const textAnimationTimeline = gsap.timeline();
    textAnimationTimeline.set(textContainer, {opacity:0});
    textAnimationTimeline.fromTo(colorOverlay, {scaleX:0.1}, {scaleX:1, duration:totalAnimationDuration/2, ease:'power2.out'});
    textAnimationTimeline.set(textContainer, {opacity:1});
    textAnimationTimeline.to(colorOverlay, {scaleX:0, x:'100%', duration:totalAnimationDuration/2, ease:'power2.inOut'});

    TemplateEngine.timeline.add(textAnimationTimeline);

    // Once all logic (including any asynchronous logic, if required) is executed, call TemplateEngine.render();
    TemplateEngine.render();
}

//TemplateEngine.update is called for every frame (in an animated template)
//Execute any frame specific logic in this function, if you have any. Once you're ready to render the frame, resolve the Promise
TemplateEngine.update = (frameNumber)=>{

    return new Promise((resolve)=>{
        resolve();
    })
}`,
        html: `<div id='container'>
    <div id = 'text-wrapper'>
        <div id = 'text-container'></div>
        <div id = 'color-overlay'></div>
    </div>
</div>`,
        css: `#container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:50px;
    font-family:'Poppins-Bold';
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('https://imposium-cdn.s3.amazonaws.com/fonts/Poppins-Bold.ttf') format('truetype');
}

#text-wrapper{
    position: relative;
}

#text-container{
    color:#353535;
    opacity: 0;
}

#color-overlay{
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background-color:#2d8ceb;
    transform-origin:top left;
    transform:scaleX(0);
}`
    };
};

export const disableForEncodingSettings = (scene) => {
    const {
        type,
        sceneData: { encodingSettings, imageOutputSettings, audioOutputSettings }
    } = scene;

    if (type === SCENE_TYPES.VIDEO || type === SCENE_TYPES.COMPOSITION) {
        if (
            encodingSettings.length === 0 &&
            imageOutputSettings.length === 0 &&
            audioOutputSettings.length === 0
        ) {
            return true;
        } else if (
            encodingSettings.length > 0 ||
            imageOutputSettings.length > 0 ||
            audioOutputSettings.length > 0
        ) {
            return false;
        }
    }

    return false;
};

export const formatTextContent = (copy, inventory): string => {
    let contentStr = copy || '';

    if (inventory) {
        // replce all instances of all inventory in the string
        for (const key in inventory) {
            if (inventory.hasOwnProperty(key)) {
                const regex = new RegExp(`{{${key}}}`, 'g');
                let val = inventory[key] || '';
                val = val.toString();
                contentStr = contentStr.replace(regex, val);
            }
        }
    }

    // If there are any leftover curly brackets (inventory wasn't set), remove them
    const varRegex = new RegExp(`{{(.*?)}}`, 'g');
    contentStr = contentStr.replaceAll(varRegex, '');

    return contentStr;
};

const mApplyVariableMapping = (variables, map) => {
    if (map) {
        const newVars = { ...variables };
        for (const key in map) {
            if (map.hasOwnProperty(key)) {
                const val = map[key];
                newVars[key] = newVars[val];
            }
        }
        return newVars;
    } else {
        return variables;
    }
};

export const applyVariableMapping = moize(mApplyVariableMapping);

export const uploadAssetsHandler = (i: any, monitor: any) => {
    const state = store.getState();
    const {
        editor: { showUploadsMenu }
    } = state;

    const apiInstance: any = api;

    if (monitor) {
        const { files } = monitor.getItem();
        const validFiles: File[] = files.filter((f: File) => validateAssetMimetype(f));

        if (validFiles.some((file) => file.size > MAX_FILE_SIZE)) {
            logError(assets.errorMaxSize);
            return;
        }

        for (const file of validFiles) {
            if (file.type === 'application/json') {
                const reader = new FileReader();
                reader.onload = (e) => store.dispatch(uploadHTMLAsset(e, file.name));
                reader.readAsText(file);
            }
        }

        const validUploadFiles = validFiles.filter((f) => f.type !== 'application/json');

        if (Array.isArray(validUploadFiles) && validUploadFiles.length > -1) {
            if (showUploadsMenu) {
                store.dispatch(updateEditorConfig({ showUploadsDropdown: true }));
            }
            store.dispatch(uploadAssets(apiInstance, validUploadFiles));
        }
    }
};
