export const SERVICE_ID: number = 1;
export const HOT_KEY: string = '3151a-a604d-496f1-d4a04-e0c43';
export const MAX_FILE_SIZE: number = 2147483648;
import { API, IImposiumAPI } from '@imposium-hub/components';
import ServiceAPI from '../util/ServiceAPI';

export let api: IImposiumAPI;
export let service_api: ServiceAPI;

// Deferred to ./components/App.tsx onMount event to avoid race condition with post auth store hydration
export const initApi = (organizationId: string, token) => {
    api = new API(import.meta.env.VITE_IMPOSIUM_BASE, token, organizationId);
    service_api = new ServiceAPI(import.meta.env.VITE_SERVICE_BASE, token);
};
