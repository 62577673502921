import { getNotificationTime } from '../../util/general';

const notifications: any = {
    LOG: 'notifications/LOG',
    CLEAR: 'notifications/LOG'
};

export const log = (text, type, button?): any => {
    const time = getNotificationTime();

    return (dispatch) => {
        dispatch({
            type: notifications.LOG,
            notification: {
                time,
                text,
                type,
                button
            }
        });
    };
};

export const clear = (): any => {
    return (dispatch) => {
        dispatch({ type: notifications.CLEAR });
    };
};

export default notifications;
