export const between = (p_string, p_start, p_end): string => {
    let str = '';
    if (p_string === null) {
        return str;
    }
    let startIdx = p_string.indexOf(p_start);
    if (startIdx !== -1) {
        startIdx += p_start.length;
        const endIdx = p_string.indexOf(p_end, startIdx);
        if (endIdx !== -1) {
            str = p_string.substr(startIdx, endIdx - startIdx);
        }
    }
    return str;
};

export const contains = (needle, haystack): boolean => {
    return haystack && haystack.indexOf(needle) !== -1;
};

export const afterLast = (p_string, p_char): string => {
    if (p_string === null) {
        return '';
    }

    let idx = p_string.lastIndexOf(p_char);
    if (idx === -1) {
        return '';
    }
    idx += p_char.length;
    return p_string.substr(idx);
};
