import actions from '../actions/asset-cache';

const initialState = {};

const assetCache = (state = initialState, action) => {
    switch (action.type) {
        case actions.CLEAR_ASSET_CACHE:
            return {};
        case actions.ADD_ASSET_TO_CACHE:
            return {
                ...state,
                ...action.assetCacheItem
            };
        default:
            return state;
    }
};

export default assetCache;
